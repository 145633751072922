<template>
  <!-- Отображение собранных заявок -->
  <div class="search">
    <item-app-group
      v-for="(item, index) in this.aboutRegistrApp.groups"
      :key="index"
      @deleteItemAppGroup="deleteItemAppGroup"
      :item_sport="item"
      :index="index"
    />
<!--    <div class="validation" v-if="this.validationErrorsAddSportsmen !== null">-->
<!--      <p class="validation__text">{{ validationErrorsAddSportsmen.message }}</p>-->
<!--    </div>-->
    <!-- Создание собранных заявок -->
    <div
      class="search__wrap-block-select"
      v-if="(this.aboutRegistrApp.status !== 'CLOSE_EDIT_APPLICATION')"
    >
      <div class="search__select-categ">
        <select-with-animate-categ
          @input="safeGroupCateg"
          :default="this.defaultCategory"
          :options="this.groupCategories"
          v-if="this.groupCategories !== null"
          ref="categSelect"
        />
      </div>
      <div class="search__add-sportsmen" v-if="safeGroupCategories !== ''">
        <custom-select-app-group
          @showModalAddGroup="showModalAddGroup"
          :selectGroupCateg="this.safeGroupCategories"
          @input="safeGroupPeople"
          :default="this.defaultGroupSporsmen"
          :options="this.allGroups"
        />
      </div>
      <div class="search__add-sportsmen-text" v-else>
        <p class="text-succses">Для выбора группы выберите категорию</p>
      </div>
    </div>
    <div class="validation" v-if="this.validationErrorsAddGroup !== null">
      <p class="validation__text">{{ validationErrorsAddGroup.message }}</p>
    </div>
    <div class="about-application" v-if="this.aboutRegistrApp">
      <p class="about-application__text">
        Итого в заявке:
        {{ this.aboutRegistrApp.groups.length }}
        групп
      </p>
    </div>
    <!-- Создание собранных заявок -->
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import { actionTypesEntered } from "@/store/modules/enteredData";
import { mapState } from "vuex";
import SelectWithAnimateCateg from "../customSelect/SelectWithAnimateCateg.vue";
import CustomSelectAppGroup from "../customSelect/CustomSelectAppGroup.vue";
import ItemAppGroup from "./ItemAppGroup.vue";
export default {
  name: "AppGroupApplication",
  data() {
    return {
      defaultCategory: "Категория группы",
      defaultGroupSporsmen: "Выберите группу",
      safeGroupCategories: "",

      listAppGroup: [],
      dataIdToIndSportsmen: {},
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      individualCategories: (state) => state.registration.individualCategories,
      individualSportsmens: (state) => state.registration.individualSportsmens,
      groupCategories: (state) => state.registration.groupCategories,
      allGroups: (state) => state.enteredData.allGroups,
      aboutRegistrApp: (state) => state.registration.aboutRegistrApp,
      validationErrorsAddGroup: (state) => state.registration.validationErrorsAddGroup,
    }),
  },
  components: {
    SelectWithAnimateCateg,
    CustomSelectAppGroup,
    ItemAppGroup,
  },
  methods: {
    deleteItemAppGroup(item) {
      this.aboutRegistrApp.sportsmans.splice(
        0,
        this.aboutRegistrApp.sportsmans.length,
        ...this.aboutRegistrApp.sportsmans.filter(
          (n) => n.idApplication !== item.idApplication
        )
      );
      this.$store
        .dispatch(actionTypes.deleteGroupApp, {
          idCompetition: this.$route.params.id,
          idApplication: item.applicationId,
        })
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    showModalAddGroup() {
      this.$emit("showModalAddGroup");
    },
    safeGroupPeople(item) {
      this.$store
        .dispatch(actionTypes.addGroupApp, {
          idCompetition: this.$route.params.id,
          idGroup: item.group.id,
          idCategory: item.categ.id,
        })
        .then(() => {})
        .catch((er) => {
          console.log(er, "Error");
        });
      this.$refs.categSelect.updateData();
    },
    safeGroupCateg(item) {
      this.safeGroupCategories = item;
    },
  },
  mounted() {
    // Получение всех групп
    this.$store
      .dispatch(actionTypesEntered.getGroups)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
