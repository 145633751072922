<template>
  <div style="width: 100%">
    <div class="accordion" :class="{ 'is-open': isOpen }">
      <div ref="excludedElement" class="" v-if="selected === this.default"  @click="toggleAccordion">
      <input
          type="text"
          class="input-light-text"
          v-model="inputValue"
          @input="filterOptions"
          :placeholder="windowWidth ? 'Добавьте спортсмена' : 'Добавьте спортсмена - введите фамилию или выберите из списка'"
      />
        <div class="input-light-text__img">
          <img src="../../assets/svg/ArrowDown.svg">
        </div>
     </div>
      <div class="accordion-header-people" v-else>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">
            {{ selected.surname }} {{ selected.name }}
          </p>
          <p class="selected-text">ФИО</p>
        </div>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">
            {{ selected.dateOfBirth }}
          </p>
          <p class="selected-text">Год рождения</p>
        </div>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">КМС</p>
          <p class="selected-text">Разряд</p>
        </div>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">Дунаева В.C.</p>
          <p class="selected-text">Тренер</p>
        </div>
      </div>
      <div  ref="body">
        <ul v-if="isOpen" class="options-list">
          <li
              v-for="(option, index) in filteredOptions"
              :key="index"
              class="option-item"
              @click="safeSelect(option)"
          >
            {{ option.surname }}  {{ option.name }}/ {{option.dateOfBirth}}<span v-if="!windowWidth">/ {{ option.rank }}</span>

          </li>
          <li style="display: flex;margin: 5px 10px;" @click="showModalAddSportsmen(), toggleAccordion()"> <img src="../../assets/svg/plus.svg" alt="plus" />
            <p class="add-text">Добавить спортсмена</p></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AppAddPeopleSelect",
    data() {
      return {
        isOpen: false,
        selected: this.default
            ? this.default
            : this.options.length > 0
                ? this.options[0]
                : null,
        selectWithCateg: {},
        filteredOptions: '',
        inputValue: '',
      };
    },
    props: {
      options: {
        type: [Array,null],
        required: true,
      },
      windowWidth: {
        type: Boolean,
        required: true,
      },
      default: {
        type: String,
        required: false,
        default: null,
      },
      tabindex: {
        type: Number,
        required: false,
        default: 0,
      },
      selectIndCateg: {
        type: Object,
        // required: true,
      },
      color: {
        type: String,
      },
    },
    watch: {
      isOpen(newValue) {
        if (newValue) {
          document.addEventListener('click', this.handleClickOutside);
        } else {
          document.removeEventListener('click', this.handleClickOutside);
        }
      },
      options(newOptions) {
        this.filteredOptions = newOptions; // Обновление filteredOptions при изменении options
      }
    },
    methods: {
      safeSelect(option) {
        this.selected = option;
        if (this.selectIndCateg) {
          this.selectWithCateg = {
            categ: this.selectIndCateg,
            sportsmen: option,
          };
          this.$emit("input", this.selectWithCateg);
          this.selected = this.default;
          this.inputValue = ''
          this.selectWithCateg = {};
        } else {
          this.$emit("input", this.selected);
          this.selected = this.default;
          this.inputValue = ''
        }
      },
      handleClickOutside(event) {
        if (this.isOpen && this.$refs.excludedElement && !this.$refs.excludedElement.contains(event.target)) {
          this.isOpen = false;
        }
      },
      filterOptions() {
        if (this.inputValue.trim() !== '') {
          this.isOpen = true;
          const query = this.inputValue.toLowerCase();
          this.filteredOptions = query
              ? this.options.filter(sportsmen => sportsmen.surname.toLowerCase().includes(query))
              : this.options;
        } else {
          // Закрытие списка опций, если ввод пустой
          this.isOpen = false;
        }
      },
      showModalAddSportsmen() {
        this.$emit("showModalAddSportsmen");
        this.inputValue = ''
      },
      toggleAccordion() {
        this.isOpen = !this.isOpen;
      },
    },
    // При уничтожении компонента убедитесь, что вы удалили обработчик событий,
    // чтобы избежать утечек памяти или других потенциальных проблем.
    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside);
    },
    mounted() {
      console.log(this.options,'options')
      this.filteredOptions = this.options;
    }
  };
</script>

