<template>
  <div class="main-content">
    <div class="loader-overlay" v-if="isLoading || isLoadingNotAuth ">
      <div class="loader"></div>
    </div>
    <Header message="Соревнования" />
    <BreadCrumps/>
    <div class="detail-competition__container" v-if="item !== null">
      <div class="detail-competition__card">
        <div class="detail-competition__card-poster">
          <img
            v-if="item.urlImage !== null"
            class="preview-block__img"
            :src="`${item.urlImage}`"
            alt="foto"
          />
          <img v-else src="../assets/img/poster.png" alt="poster" />
        </div>
        <div class="detail-competition__card-info">
          <div class="detail-competition__cart-info-title">
            <p class="title-text">
              {{ item.title }}
            </p>
          </div>
          <div class="detail-competition__cart-info-text">
            <p class="text-label">Место проведения:</p>
            <p class="text-description">
              {{ item.place }}
            </p>
          </div>
          <div class="detail-competition__cart-info-date">
            <div class="detail-competition__cart-info-date-from">
              <p class="text-label">Дата проведения:</p>
              <p class="text-description">
                {{ dateOfFromComp }}-{{ dateOfToComp }}
              </p>
            </div>
            <div class="detail-competition__cart-info-date-to">
              <p class="text-label">Приём заявок до:</p>
              <p class="text-description">{{ dateOfPrismApp }}</p>
            </div>
          </div>
          <div class="detail-competition__wrap-btn">
            <!-- Проверяем наличие токена -->
            <template v-if="token !== ''">
              <!-- Блок кнопок для заявок -->
              <div v-if="item.status === 'CLOSE_EDIT_APPLICATION'" class="detail-competition__end-reg">
                <div class="message-about-empty empty">
                  <p class="message-about-empty__text">Прием заявок окончен!</p>
                </div>
                <div class="detail-competition__btn">
                  <button class="button" @click="continueRegister()">
                    <img src="../assets/svg/eyeWhite.svg" alt="eye" class="button__img" />
                    Редактировать заявку
                  </button>
                </div>
              </div>

              <div v-if="item.status === 'OPEN_NOT_APPLICATION'" class="detail-competition__btn">
                <button class="button-green" @click="continueDraft()">
                  Подать заявку
                </button>
              </div>

              <div v-if="item.status === 'OPEN_EDIT_APPLICATION'" class="detail-competition__btn">
                <button class="button-green" @click="continueRegister()">
                  Редактировать заявку
                </button>
              </div>

              <div v-if="item.status === 'CLOSE_NOT_APPLICATION'" class="message-about-empty empty">
                <p class="message-about-empty__text">Прием заявок окончен!</p>
              </div>
            </template>
            <!-- Если нет токена, отображаем кнопку для входа -->
            <template v-else>
              <div class="detail-competition__btn">
                <button class="button" @click="login()">
                  Войти в аккаунт
                </button>
              </div>
            </template>
          </div>

        </div>
      </div>
      <div class="detail-competition-description">
        <div class="detail-competition-description__title">
          <p class="title-text">Описание:</p>
        </div>
        <div class="detail-competition-description__block">
          <p class="text-description">
            {{ item.description }}
          </p>
        </div>
      </div>
      <div class="detail-competition-category">
        <div class="detail-competition-category__title">
          <p class="title-text">Категории спортсменов:</p>
        </div>
        <div class="detail-competition-category__categ">
          <div class="detail-competition-category__label">
            <p class="text-label">Индивидуальная программа</p>
          </div>
          <div class="detail-competition-category__wrap">
            <item-individual-categories
              v-for="item in item.individualCategory"
              :key="item.id"
              v-bind:item_ind_categ="item"
            />
          </div>
          <div class="detail-competition-category__label2" v-if="item.groupResponses.length">
            <p class="text-label">Групповые упражнения</p>
          </div>
          <div class="detail-competition-category__wrap">
            <item-group-categ
              v-for="item in item.groupResponses"
              :key="item.id"
              v-bind:item_group_categ="item"
            />
          </div>
        </div>
      </div>
    </div>
    <modal-application
      v-if="isOpenModalApplication"
      :item_app="itemOpenApplication"
      @close="isOpenModalApplication = false"
    />
  </div>
</template>

<script>
import ItemGroupCateg from "../components/DetailCard/ItemGroupCateg.vue";
import ItemIndividualCategories from "../components/DetailCard/ItemIndividualCategories.vue";
import Header from "../components/Header.vue";
import { mapState } from "vuex";
import { actionTypes } from "@/store/modules/competition";
import ModalApplication from "../components/Modal/ModalApplication.vue";
import BreadCrumps from '@/components/BreadCrumps.vue'
import { actionTypesWithoutAuth } from '@/store/modules/withoutAuthorization'

export default {
  components: {
    BreadCrumps,
    Header,
    ItemGroupCateg,
    ItemIndividualCategories,
    ModalApplication,
  },
  name: "AppItemDetailedCardCompetition",
  data() {
    return {
      isOpenModalApplication: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.competition.isLoading,
      isLoadingNotAuth: (state) => state.withoutAuth.isLoading,
      item: (state) => state.auth.token !== '' ? state.competition.itemCompetition : state.withoutAuth.itemCompetitionNotAuth,
      token: (state) => state.auth.token,
    }),
    dateOfFromComp() {
      return this.$moment(this.item.fromDate)
        .locale("ru")
        .format("LL");
    },
    dateOfToComp() {
      return this.$moment(this.item.toDate)
        .locale("ru")
        .format("LL");
    },
    dateOfPrismApp() {
      return this.$moment(this.item.applicationsBefore)
        .locale("ru")
        .format("LL");
    },
  },
  methods: {
    continueDraft() {
      this.$router.push({
        name: "New-Application",
        params: { id: this.item.id },
      });
    },
    continueRegister() {
      this.$router.push({
        name: "Edit-Application",
        params: { id: this.item.id },
      });
    },
    login() {
      this.$router.push({
        name: "Login",
      });
    },
    openModalApp() {
      this.isOpenModalApplication = true;
    },

  },
  mounted() {
    if(this.token !== '') {
      this.$store
          .dispatch(actionTypes.idCompetition, this.$route.params.id)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
    } else {
      this.$store
          .dispatch(actionTypesWithoutAuth.idCompetition, this.$route.params.id)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }

  },
};
</script>

<style></style>
