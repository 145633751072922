<template>
  <div class="creation__container">
    <div class="creation__collection-info" v-if="competitionItemInfo !== null">
      <div class="creation__input">
        <p class="text-label" for="title">Название вашего мероприятия:</p>
        <input
          type="text"
          class="input-light-text"
          placeholder="Например: Кубок Города Новосибирска"
          name="title"
          @blur="handleBlur"
          v-model="competitionItemInfo.title"
        />
      </div>
      <div class="creation__input">
        <p class="text-label" for="place">
          Место проведения соревнований:
        </p>
        <input
          type="text"
          class="input-light-text"
          placeholder="Например: Дворец спорта НГТУ"
          name="place"
          @blur="handleBlur"
          v-model="competitionItemInfo.place"
        />
      </div>
      <div class="creation__input">
        <p class="text-label" for="place">Описание соревнований:</p>
        <textarea
          v-model="competitionItemInfo.description"
          @blur="handleBlur"
          class="input-text-index"
          placeholder="Добавьте описание соревнования"
          name="description"
        ></textarea>
      </div>
      <div class="creation__input-row">
        <div class="creation__input-row-block-two">
          <p class="text-label" for="city">Город:</p>
          <input
              type="text"
              class="input-light-text"
              placeholder="Новосибирск"
              name="city"
              @blur="handleBlur"
              v-model="competitionItemInfo.city"
          />
        </div>
        <div class="creation__input-row-block-two">
          <p class="text-label" for="region">Область/регион*</p>
          <input
              type="text"
              class="input-light-text"
              placeholder="Новосибирская область"
              name="region"
              @blur="handleBlur"
              v-model="competitionItemInfo.region"
          />
<!--          <select-->
<!--            name="region"-->
<!--            class="input-light-text"-->
<!--            @blur="handleBlur"-->
<!--            v-model="competitionItemInfo.region"-->
<!--          >-->
<!--            <option value="Новосибирская область" selected>-->
<!--              Новосибирская область-->
<!--            </option>-->
<!--          </select>-->
        </div>
      </div>

        <div class="">
          <div class="creation__input-row">
            <div class="creation__input-row-block">
              <p class="text-label" for="mainJudge">
                Главный судья соревнований (ФИО) :
              </p>
              <input
                type="text"
                class="input-light-text"
                placeholder="Иванов Иван Васильевич"
                name="mainJudge"
                @blur="handleBlur"
                v-model="competitionItemInfo.mainJudge"
              />
            </div>
            <div class="creation__input-row-block">
              <p class="text-label" for="judgeCategory">
                Судейская категория
              </p>
              <select
                name="judgeCategory"
                class="input-light-text"
                @blur="handleBlur"
                v-model="competitionItemInfo.judgeCategory"
              >
                <option value="б/к" selected>б/к</option>
                <option value="СС3К" selected>3 категория</option>
                <option value="СС2К">2 категория</option>
                <option value="СС1К">1 категория</option>
                <option value="ССВК">ССВК</option>
              </select>
            </div>
            <div class="">
              <p class="text-label" for="city">Город гл.судьи:</p>
              <input
                  type="text"
                  class="input-light-text"
                  placeholder="Новосибирск"
                  name="cityMainJudge"
                  @blur="handleBlur"
                  v-model="competitionItemInfo.cityMainJudge"
              />
<!--              <select-->
<!--                  name="city"-->
<!--                  class="input-light-text"-->
<!--                  @blur="handleBlur"-->
<!--                  v-model="competitionItemInfo.cityMainJudge"-->
<!--              >-->
<!--                <option value="Новосибирск" selected>Новосибирск</option>-->
<!--                <option value="Академгородок">Академгородок</option>-->
<!--                <option value="Бердск">Бердск</option>-->
<!--              </select>-->
            </div>
          </div>
          <div class="creation__input-row">
            <div class="creation__input-row-block">
              <p class="text-label" for="chiefSecretary">
                Главный секретарь соревнований (ФИО) :
              </p>
              <input
                type="text"
                class="input-light-text"
                placeholder="Иванов Иван Васильевич"
                name="chiefSecretary"
                @blur="handleBlur"
                v-model="competitionItemInfo.chiefSecretary"
              />
            </div>
            <div class="creation__input-row-block">
              <p class="text-label" for="chiefCategory">
                Судейская категория
              </p>
              <select
                name="chiefCategory"
                class="input-light-text"
                @blur="handleBlur"
                v-model="competitionItemInfo.chiefCategory"
              >
                <option value="б/к" selected>б/к</option>
                <option value="СС3К">3 категория</option>
                <option value="СС2К">2 категория</option>
                <option value="СС1К">1 категория</option>
                <option value="ССВК">ССВК</option>
              </select>
            </div>
            <div class="">
              <p class="text-label" for="city">Город гл.секретаря:</p>
              <input
                  type="text"
                  class="input-light-text"
                  placeholder="Новосибирск"
                  name="cityChiefSecretary"
                  @blur="handleBlur"
                  v-model="competitionItemInfo.cityChiefSecretary"
              />
<!--              <select-->
<!--                  name="city"-->
<!--                  class="input-light-text"-->
<!--                  @blur="handleBlur"-->
<!--                  v-model="competitionItemInfo.cityChiefSecretary"-->
<!--              >-->
<!--                <option value="Новосибирск" selected>Новосибирск</option>-->
<!--                <option value="Академгородок">Академгородок</option>-->
<!--                <option value="Бердск">Бердск</option>-->
<!--              </select>-->
            </div>
          </div>
          <div class="creation__wrap-info-with-img">
            <div class="">
          <div class="creation__input-row">
            <div class="creation__column">
              <p class="text-label" for="fromDate">
                Даты проведения соревнований:
              </p>
              <div class="creation__row">
                <input
                  type="date"
                  class="input-light-text"
                  placeholder="Время, место"
                  name="fromDate"
                  @blur="handleBlur"
                  v-model="competitionItemInfo.fromDate"
                />
                <p class="text-label" for="toDate">до</p>
                <div class="margin-left15">
                  <input
                    type="date"
                    class="input-light-text"
                    placeholder="Время, место"
                    name="toDate"
                    @blur="handleBlur"
                    v-model="competitionItemInfo.toDate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="creation__input-date">
            <p class="text-label" for="applicationsBefore">
              Подача заявок до:
            </p>
            <input
              type="date"
              class="input-light-text"
              placeholder="Время, место"
              name="applicationsBefore"
              @blur="handleBlur"
              v-model="competitionItemInfo.applicationsBefore"
            />
          </div>
            </div>
            <div class="creation__poster">
              <p class="text-label" for="place">Прикрепить постер:</p>

              <add-poster
                  v-bind:id="id"
                  v-bind:competitionItemInfo="competitionItemInfo"
              />
            </div>
        </div>

      </div>
<!--       <div class="creation__input-date">-->
<!--        <p class="text-label" for="applicationsBefore">Документация:</p>-->
<!--        <app-add-documents-->
<!--          v-bind:color="colorDownload"-->
<!--          v-bind:competitionItemInfo="competitionItemInfo"-->
<!--        />-->
<!--      </div>-->
    </div>
    <div class="creation__btn">
      <button class="button" @click="switchTabsClass">Далее</button>
    </div>
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registrationComp";
import { mapState } from "vuex";
import AddPoster from "./AddPoster.vue";
// import AppAddDocuments from '@/components/AddDocuments.vue'
export default {
  components: {
    // AppAddDocuments,
    AddPoster,
    //  AddDocuments
  },
  name: "AppDraftIndexInfoAboutCompetition",
  data() {
    return {
      infoCompetition: {},
      colorDownload: "#f0f0f0",
    };
  },
  computed: {
    ...mapState({
      competitionItemInfo: (state) =>
        state.registrationComp.competitionItemInfo,
    }),
  },
  // props: {
  //   id: {
  //     type: Number || String,
  //     default() {
  //       return {};
  //     },
  //   },
  // },
  props: ["id"],
  methods: {
    handleBlur(e) {
      this.infoCompetition = {
        competitionId: this.competitionItemInfo.id,
        [e.target.name]: e.target.value,
      };
      this.$store
        .dispatch(actionTypes.competitionsRegFirst, this.infoCompetition)
        .then(() => {
          this.infoCompetition = {};
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    switchTabsClass() {
      this.$emit("switchTabsClass", "individual");
    },
  },
};
</script>

<style></style>
