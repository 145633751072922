<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask" :style="{ 'z-index': 1000 }">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>

          <form @submit.prevent="safeInfoSportsmen">
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Добавление спортсмена</p>
              </div>
              <ValidationErrors
                  v-if="validationErrorsSportsmen"
                  :validation-errors="validationErrorsSportsmen.message"
              />
              <div class="modal-scroll-max">

              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Фамилия:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input': isSubmitted && $v.surname.$error,
                    }"
                    placeholder="Заполните фамилию"
                    name="surname"
                    v-model="surname"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.surname.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Имя:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input': isSubmitted && $v.name.$error,
                    }"
                    placeholder="Заполните имя"
                    name="name"
                    v-model="name"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.name.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>
                <div class="">
                  <div class="input-wrap">
                    <div class="input-wrap__label">
                      <p class="text-label">Отчество:</p>
                    </div>
                    <input
                        type="text"
                        class="input-light-text"
                        :class="{
                      'error-border-input': isSubmitted && $v.patronymic.$error,
                    }"
                        placeholder="Заполните отчество"
                        name="patronymic"
                        v-model="patronymic"
                    />
                    <img
                        src="~@/assets/svg/Error.svg"
                        alt="error"
                        v-if="isSubmitted && $v.patronymic.$error"
                        class="wrapValidationInput__error"
                    />
                  </div>
                </div>
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Дата рождения:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :min="minDate"
                    :max="maxDate"
                    v-mask="'##.##.####'"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.DateOfBirth.$error,
                    }"
                    placeholder="01.01.2000"
                    v-model="DateOfBirth"
                  />
<!--                  @input="updateDateOfBirth($event.target.value)"-->
<!--                  :value="formattedDateOfBirth"-->
<!--                  v-model="DateOfBirth"-->
                </div>
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Разряд имеет:</p>
                  </div>
                  <custom-select-rank
                    v-if="this.selectRank !== null"
                    @input="safeRank"
                    :class="{
                      'error-border-input': isSubmitted && $v.rank.$error,
                    }"

                    :default="this.defaultRang"
                    :options="this.selectRank"
                  />
                </div>

              </div>
                <div
                    v-if="isSubmitted && !$v.checkedGender.required"
                    class="validation"
                >
                  <p class="validation__text">*Выберите пол</p>
                </div>
                <div class="input-wrap__row-gender">

                  <div class="input-wrap__label-row">
                    <p class="text-label">Пол:</p>
                  </div>
               <div class="input-wrap__radio-btn">
                 <div class="form__group-row">
                   <input
                       type="radio"
                       name="options"
                       id="genderM"
                       class="radio-tag"
                       value="GIRL"
                       v-model="checkedGender"
                   />
                   <label for="genderM">Ж</label>
                 </div>
                 <div class="form__group-row">
                   <input
                       type="radio"
                       name="options"
                       id="genderG"
                       class="radio-tag"
                       value="MAN"
                       v-model="checkedGender"
                   />
                   <label for="genderG">М</label>
                 </div>
                  </div>
                </div>
              <div class="modal-body__table">
                <div
                  v-if="isSubmitted && !$v.checkedCoachID.required"
                  class="validation"
                >
                  <p class="validation__text">*Назначьте тренера</p>
                </div>

                <div class="input-wrap__label">
                  <p class="text-label">Тренеры:</p>
                </div>
                <table-coach
                  ref="check"
                  @addCoach="addCoach"
                  @checkedCoach="checkedCoach"
                />
              </div>
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Область/регион:</p>
                  </div>

                  <AppRegionSelect @regionSelected="safeRegion"/>
                </div>
                <div class="input-wrap__label-with-input-row" v-if="this.region">
                  <div class="input-wrap__label">
                    <p class="text-label">Город:</p>
                  </div>
                <AppCitySelect :idRegion="this.region.id"  @citySelected="safeCity" />
                </div>
              </div>
                <div
                    class="message-about-empty empty"
                    v-if="isSubmitted && this.$v.$invalid"
                >
                  <p class="message-about-empty__text">Заполните все данные!</p>
                </div>
            </div>

            </div>
            <div class="modal-footer">
              <div class="modal-footer__button">
                <button class="button">Сохранить</button>
              </div>
              <div class="modal-footer__button">
                <button
                    type="button"
                    class="button-transponent"
                    @click="$emit('close')"
                >
                  Отменить
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import CustomSelect from "../CustomSelect.vue";
import { actionTypes } from "@/store/modules/registration";
import  { actionTypesEntered } from "@/store/modules/enteredData";
import { mapState } from "vuex";
import TableCoach from "../TableInModal/TableCoach.vue";
import CustomSelectRank from "../customSelect/CustomSelectRank.vue";
// import CustomSelectUniversal from "../customSelect/CustomSelectUniversal.vue";
import { required } from "vuelidate/lib/validators";
import ValidationErrors from '@/components/ValidationErrors.vue'
import AppRegionSelect from '@/components/customSelect/RegionSelect.vue'
import AppCitySelect from '@/components/customSelect/CitySelect.vue'

// import DatePicker from "vue2-datepicker";
export default {
  name: "AppModalAddSportsmen",
  data() {
    return {
      name: "",
      surname: "",
      DateOfBirth: "",
      region: "",
      city: "",
      rank: "",
      patronymic: "",
      checkedCoachID: [],
      selectRegion: ["Новосибирская область"],
      selectCity: ["Новосибирск", "Краснообск", "Бердск", "Академгородок"],
      defaultRegion: "Выберите регион",
      defaultCity: "Выберите город",
      defaultRang: "Выберите разряд",
      isSubmitted: false,
      minDate: '1990-01-01', // Ограничение по минимальной дате рождения
      maxDate: '2023-12-31', // Ограничение по максимальной дате рождения
      checkedGender: '',
    };
  },
  components: {
    AppCitySelect,
    AppRegionSelect,

    ValidationErrors,
    TableCoach,
    CustomSelectRank,
    // CustomSelectUniversal,
    // DatePicker
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      selectRank: (state) => state.registration.selectRank,
      allCityAndRegion: (state) => state.registration.allCityAndRegion,
      validationErrorsCityAndRegion: (state) => state.registration.validationErrorsCityAndRegion,
      validationErrorsSportsmen: (state) => state.enteredData.validationErrorsSportsmen
    }),
  },
  validations: {
    name: {
      required,
    },
    patronymic: {
      // required
    },
    surname: {
      required,
    },
    DateOfBirth: {
      required,
    },
    region: {
      required,
    },
    rank: {
      required,
    },
    city: {
      required,
    },
    checkedGender: {
      required,
    },
    checkedCoachID: {
      required,
    },
  },
  methods: {
    updateDateOfBirth(value) {
      // Обновляем модель данных DateOfBirth при вводе
      this.DateOfBirth = value;
    },

    safeInfoSportsmen() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return console.log("Данные не заполенены");
      } else {
        this.$store
          .dispatch(actionTypesEntered.registrSportsmen, {
            name: this.name,
            surname: this.surname,
            patronymic: this.patronymic,
            dateOfBirth: this.DateOfBirth,
            region: this.region.name,
            city: this.city,
            coachId: this.checkedCoachID,
            gender: this.checkedGender,
            rank: this.rank.id,
          })
          .then(() => {
            //получение всех спортсменов
            this.name = "";
            this.surname = "";
            this.patronymic = "";
            this.DateOfBirth = "";
            this.region = "";
            this.rank = "";
            this.checkedGender = "";
            this.checkedCoachID = [];
            this.$emit("close");
            // this.$emit("createNewPeople");
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
    },
    checkedCoach(item) {
      this.checkedCoachID = item;
    },
    addCoach() {
      this.$emit("addCoach");
    },
    safeCity(item) {
      this.city = item;
    },
    safeRegion(item) {
      this.region = item;
    },
    safeRank(item) {
      console.log(item, "rank");
      this.rank = item;
    },
  },
  mounted() {
    //Получение всех тренеров
    this.$store
      .dispatch(actionTypesEntered.getCoaches)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
    //получение все разрядов
    this.$store
      .dispatch(actionTypes.getRank)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });

  },
};
</script>

<style></style>
