<template>
  <div class="main-content">
    <Header message="Мои данные" />
    <div class="entered-data__container">
      <AppMiniRouting/>
      <div class="entered-data__title-with-svg">
        <p class="entered-data__title">Спортсмены</p>
        <div class="entered-data__wrap-svg" @click="openModalAddSportsmen()">
          <img src="../assets/svg/plus.svg" alt="plus" />
        </div>
      </div>
      <div class="entered-data__block-wrap">
        <TableSportsmen
          v-if="this.allSportsmens && this.allSportsmens.length"
          @editSportsmen="editSportsmen"
          @deleteSportsmen="deleteItem"
        />
        <empty-block v-else />
      </div>
      <div class="entered-data__title-with-svg">
        <p class="entered-data__title">Группы</p>
        <div class="entered-data__wrap-svg" @click="openModalAddGroup()">
          <img src="../assets/svg/plus.svg" alt="plus" />
        </div>
      </div>
      <div class="entered-data__block-wrap">

        <TableGroup
          v-if="this.allGroups && this.allGroups.length"
          @deleteGroup="deleteItem"
          @editGroup="editGroup"
        />
        <empty-block v-else />
      </div>
      <div class="">
        <div class="entered-data__title-with-svg">
          <p class="entered-data__title">Тренеры</p>
          <div class="entered-data__wrap-svg" @click="openModalAddCoach()">
            <img src="../assets/svg/plus.svg" alt="plus" />
          </div>
        </div>
        <div class="entered-data__block-wrap">
          <TableCoach
            v-if="this.coaches && this.coaches.length"
            @deleteCoach="deleteItem"
            @editCoach="editCoach"
          />
          <empty-block v-else />
        </div>
        <div class="entered-data__title-with-svg">
          <p class="entered-data__title">Судьи</p>
          <div class="entered-data__wrap-svg" @click="openModalAddJuj()">
            <img src="../assets/svg/plus.svg" alt="plus" />
          </div>
        </div>
        <div class="entered-data__block-wrap">

          <TableJuj
            v-if="this.judges && this.judges.length"
            @deleteJuj="deleteItem"
            @editJuj="editJuj"
          />
          <empty-block v-else />
        </div>
      </div>
    </div>
    <modal-add-sportsmen
        v-if="showAddSportsmen"
        @close="closeModal('showAddSportsmen')"
        @addCoach="openModal('isShowAddCoach')"
    />
    <modal-modification-sportsmen
        v-if="isShowModifSportsmen"
        @close="closeModal('isShowModifSportsmen')"
        :item_sportsmen="this.item_sportsmen"
    />
    <modal-add-coach
        v-if="isShowAddCoach"
        @close="closeModal('isShowAddCoach')"
    />
    <modal-modification-coach
        v-if="isShowModifCoach"
        @close="closeModal('isShowModifCoach')"
        :item_coach="this.item_coach"
    />
    <modal-add-group
        v-if="isShowAddGroup"
        @close="closeModal('isShowAddGroup')"
        @addCoach="openModal('isShowAddCoach')"
        @addSportsmen="openModal('showAddSportsmen')"
    />
    <modal-modification-group
        v-if="isShowModiGroup"
        @close="closeModal('isShowModiGroup')"
        :item_group="this.item_group"
    />
    <modal-add-juj
        v-if="isShowAddJuj"
        @close="closeModal('isShowAddJuj')"
    />
    <modal-modification-juj
        v-if="isShowModiJuj"
        @close="closeModal('isShowModiJuj')"
        :item_juj="this.item_juj"
    />
    <modal-delete
        v-if="isShowDelete"
        @close="closeModal('isShowDelete')"
        :item="this.item_delete"
        :role="this.role_to_delete"
    />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import ModalAddSportsmen from "../components/Modal/ModalAddSportsmen.vue";
import TableSportsmen from "../components/Table/TableSportsmen.vue";
import { actionTypesEntered } from "@/store/modules/enteredData";
import ModalModificationSportsmen from "../components/Modal/ModalModificationSportsmen.vue";
import TableCoach from "../components/Table/TableCoach.vue";
import ModalAddCoach from "../components/Modal/ModalAddCoach.vue";
import ModalModificationCoach from "../components/Modal/ModalModificationCoach.vue";
import TableGroup from "../components/Table/TableGroup.vue";
import ModalAddGroup from "../components/Modal/ModalAddGroup.vue";
import ModalModificationGroup from "../components/Modal/ModalModificationGroup.vue";
import ModalAddJuj from "../components/Modal/ModalAddJuj.vue";
import TableJuj from "../components/Table/TableJuj.vue";
import ModalModificationJuj from "../components/Modal/ModalModificationJuj.vue";
import { mapState } from "vuex";
import ModalDelete from "../components/Modal/ModalDelete.vue";
import EmptyBlock from "../components/EmptyBlock.vue";
import AppMiniRouting from '@/components/MiniRouting.vue'
export default {
  components: {
    AppMiniRouting,
    Header,
    ModalAddSportsmen,
    TableSportsmen,
    ModalModificationSportsmen,
    TableCoach,
    ModalAddCoach,
    ModalModificationCoach,
    TableGroup,
    ModalAddGroup,
    ModalModificationGroup,
    ModalAddJuj,
    TableJuj,
    ModalModificationJuj,
    ModalDelete,
    EmptyBlock,
  },
  name: "EnteredData",
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      //Спортсмены
      allSportsmens: (state) => state.enteredData.allSportsmens,
      //Группы
      allGroups: (state) => state.enteredData.allGroups,
      //Судьи
      judges: (state) => state.enteredData.allJuj,
      //Тренеры
      coaches: (state) => state.enteredData.allCoaches,
    }),
  },
  data() {
    return {
      showAddSportsmen: false,
      isShowModifSportsmen: false,
      isShowAddCoach: false,
      isShowModifCoach: false,
      isShowAddGroup: false,
      isShowModiGroup: false,
      isShowModiJuj: false,
      isShowAddJuj: false,
      isShowDelete: false,
      item_sportsmen: null,
      item_coach: null,
      item_group: null,
      item_juj: null,
      //На удаление
      item_delete: null,
      role_to_delete: null,
    };
  },
  methods: {
    closeModal(modalProperty) {
      this[modalProperty] = false;
      // Проверяем, нужно ли оставить скролл заблокированным (если есть другие открытые модальные окна)
      if (!this.anyModalOpen()) {
        document.body.classList.remove('no-scroll');
      }
    },
    anyModalOpen() {
      // Проверяем, открыто ли еще какое-либо модальное окно
      return this.showAddSportsmen || this.isShowModifSportsmen || this.isShowAddCoach ||
          this.isShowModifCoach || this.isShowAddGroup || this.isShowModiGroup ||
          this.isShowModiJuj || this.isShowAddJuj || this.isShowDelete;
    },
    openModal(modalProperty) {
      this[modalProperty] = true;
      document.body.classList.add('no-scroll');
    },
    openModalAddSportsmen() {
      this.showAddSportsmen = true;
      document.body.classList.add('no-scroll');
    },
    // closeModal() {
    //   document.body.classList.remove('no-scroll');
    // },
    openModalAddCoach() {
      this.isShowAddCoach = true;
      document.body.classList.add('no-scroll');
    },
    openModalAddGroup() {
      this.isShowAddGroup = true;
      document.body.classList.add('no-scroll');
    },
    openModalAddJuj() {
      this.isShowAddJuj = true;
      document.body.classList.add('no-scroll');
    },
    editSportsmen(item) {
      this.isShowModifSportsmen = true;
      this.item_sportsmen = item;
      document.body.classList.add('no-scroll');
    },
    editCoach(item) {
      this.item_coach = item;
      this.isShowModifCoach = true;
      document.body.classList.add('no-scroll');
    },
    editGroup(item) {
      this.item_group = item;
      this.isShowModiGroup = true;
      document.body.classList.add('no-scroll');
    },
    editJuj(item) {
      this.item_juj = item;
      this.isShowModiJuj = true;
      document.body.classList.add('no-scroll');
    },
    deleteItem(item, role) {
      this.role_to_delete = null;
      this.item_delete = item;
      console.log(role, "role");
      this.role_to_delete = role;
      // console.log(role);
      this.isShowDelete = true;
    },
  },
  mounted() {
    //получение всех спортсменов
    this.$store
      .dispatch(actionTypesEntered.getSportsmens)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
    //Получение всех тренеров
    this.$store
      .dispatch(actionTypesEntered.getCoaches)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
    // Получение всех групп
    this.$store
      .dispatch(actionTypesEntered.getGroups)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
    //Получение всех судей
    this.$store
      .dispatch(actionTypesEntered.getJuj)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
