<template>
  <table class="table">
    <tbody>
      <item-table-coach
        v-for="(coach, i) in this.coaches"
        :key="i"
        :item_coach="coach"
        v-model="checkedCoach"
      />
      <tr @click="addCoach" class="add-person-button">
        <td class="add-person">
          <img
            src="../../assets/svg/plus.svg"
            class="add-person__img"
            alt="cross"
          />
        </td>

        <td class="td-add-name">Создать тренера</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from "vuex";
import ItemTableCoach from "./ItemTableCoach.vue";
export default {
  components: { ItemTableCoach },
  name: "AppTableCoach",
  data() {
    return {
      checkedCoach: [],
    };
  },
  watch: {
    checkedCoach: function () {
      this.$emit("checkedCoach", this.checkedCoach ? this.checkedCoach : null);
    },
  },
  // props: ["checkID"],
  methods: {
    submitData() {
      this.$emit("checkCoach", this.checkedCoach);
    },
    addCoach() {
      this.$emit("addCoach");
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      coaches: (state) => state.enteredData.allCoaches,
    }),
  },
};
</script>

<style></style>
