<template>
  <div class="main-content" v-if="this.aboutRegistrApp !== null">
    <Header message="Создание заявки" />
    <BreadCrumps/>
    <div class="loader-overlay" v-if="isLoadingCreateApp">
      <div class="loader"></div>
    </div>
    <div class="create-application__container">
      <div class="create-application__information">
        <div class="create-application__select-block">
          <div class="create-application__select-block-label">
            <p class="text-label-dark">Мероприятие, на которое будет подана заявка:</p>
          </div>
          <div class="input-with-loader" v-if="isLoadingInfo">
            <label class="input-loader">
              <lottie-animation
                  ref="anim"
                  :animationData="require('@/assets/lottie/input_loader.json')"
                  :autoPlay="true"
                  :loop="true"
                  :speed="1"
              />
            </label>
          </div>
          <div v-if="!isLoadingInfo">
            <input
                type="text"
                class="input-light-text"
                placeholder="Заполните организацию"
                name="comment"
                disabled
                v-model="aboutRegistrApp.title"
            />
          </div>
        </div>
        <div class="create-application__select-block">
          <div class="create-application__select-block-label">
            <p class="text-label-dark">Организация подающая заявку:</p>
          </div>
          <div class="input-with-loader" v-if="isLoadingInfo">
            <label class="input-loader">
              <lottie-animation
                  ref="anim"
                  :animationData="require('@/assets/lottie/input_loader.json')"
                  :autoPlay="true"
                  :loop="true"
                  :speed="1"
              />
            </label>
          </div>
          <div v-if="!isLoadingInfo">
            <input
                type="text"
                class="input-light-text"
                placeholder="Заполните организацию"
                name="comment"
                :class="{'error-border-input': isSubmitted && $v.dataSubmit.department.$error}"
                v-model="aboutRegistrApp.department"
            />
          </div>
        </div>
        <div class="create-application__region">
          <div class="create-application__region-wrap">
            <div class="create-application__select-block-label">
              <p class="text-label-dark">Область/регион*:</p>
            </div>
            <div class="input-with-loader" v-if="isLoadingInfo">
              <label class="input-loader">
                <lottie-animation
                    ref="anim"
                    :animationData="require('@/assets/lottie/input_loader.json')"
                    :autoPlay="true"
                    :loop="true"
                    :speed="1"
                />
              </label>
            </div>
            <div v-if="!isLoadingInfo">
              <AppRegionSelect @regionSelected="safeRegion" :default="aboutRegistrApp.region" :class="{'error-border-input': isSubmitted && $v.dataSubmit.region.$error}"/>
            </div>
          </div>
          <div class="create-application__region-wrap" v-if="region !== null">
            <div class="create-application__select-block-label">
              <p class="text-label-dark">Город*</p>
            </div>
            <div class="input-with-loader" v-if="isLoadingInfo">
              <label class="input-loader">
                <lottie-animation
                    ref="anim"
                    :animationData="require('@/assets/lottie/input_loader.json')"
                    :autoPlay="true"
                    :loop="true"
                    :speed="1"
                />
              </label>
            </div>
            <div v-if="!isLoadingInfo">
              <AppCitySelect :idRegion="region ? region.id : null" @citySelected="safeCity" :default="aboutRegistrApp.city"  :class="{'error-border-input': isSubmitted && $v.dataSubmit.city.$error}"/>
            </div>
          </div>
        </div>
      </div>
      <div class="validation" v-if="validationErrorsSubmitApplication !== null">
        <p class="validation__text">{{ validationErrorsSubmitApplication.message }}</p>
      </div>
      <div
          class="validation"
          v-if="isSubmitted && this.$v.$invalid"
      >
        <p class="validation__text">Заполните все данные!</p>
      </div>
      <div class="create-application__tab">

        <tab-menu-application
            ref="tabMenu"
            @showModalAddSportsmen="showModalAddSportsmen"
            @showModalAddGroup="showModalAddGroup"
            @showModalAddCoath="showModalAddCoath"
            @showModalAddJuj="showModalAddJuj"
            @submitApplication="submitApplication"
        />
      </div>

    </div>
    <modal-add-sportsmen
        v-if="showAddSportsmen"
        @addCoach="showAddCoach = true"
        @close="showAddSportsmen = false"
    />
    <modal-add-coach
        v-if="showAddCoach"
        @close="showAddCoach = false"
        @closeCoachOpenSport="(showAddCoach = false), (showAddSportsmen = true)"
    />
    <modal-add-juj
        v-if="showAddJuj"
        @close="showAddJuj = false"
        @closeCoachOpenSport="showAddJuj = false"
    />
    <modal-add-group
        v-if="showAddGroup"
        @close="showAddGroup = false"
        @addCoach="showAddCoach = true"
        @addSportsmen="showModalAddSportsmen"
    />
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import { mapState } from 'vuex'
  import { actionTypes } from "@/store/modules/registration";
  import { actionTypesEntered } from "@/store/modules/enteredData";
  import Header from '../components/Header.vue'
  import BreadCrumps from '@/components/BreadCrumps.vue'
  import AppRegionSelect from '@/components/customSelect/RegionSelect.vue'
  import AppCitySelect from '@/components/customSelect/CitySelect.vue'
  import LottieAnimation from 'lottie-web-vue'
  import ModalAddSportsmen from '../components/Modal/ModalAddSportsmen.vue'
  import TabMenuApplication from '../components/CreateApplication/TabMenuApplication.vue'
  import ModalAddGroup from '../components/Modal/ModalAddGroup.vue'
  import ModalAddJuj from '../components/Modal/ModalAddJuj.vue'
  import ModalAddCoach from '../components/Modal/ModalAddCoach.vue'

  export default {
    name: "AppCreateApplication",
    components: {
      Header, BreadCrumps, AppRegionSelect, AppCitySelect, LottieAnimation,
      ModalAddSportsmen, TabMenuApplication, ModalAddGroup, ModalAddJuj, ModalAddCoach
    },
    data() {
      return {
        showAddSportsmen: false,
        showAddGroup: false,
        showAddCoach: false,
        showAddJuj: false,
        city: '',
        region: '',
        dataSubmit: {},
        isSubmitted: false
      };
    },
    validations: {
      dataSubmit: { region: { required }, city: { required }, department: { required } }
    },
    computed: {
      ...mapState({
        isLoadingInfo: state => state.registration.isLoadingInfo,
        isLoadingCreateApp: state => state.registration.isLoadingCreateApp,
        aboutRegistrApp: state => state.registration.aboutRegistrApp,
        infoUser: state => state.auth.infoUser,
        validationErrorsSubmitApplication: state => state.registration.validationErrorsSubmitApplication
      })
    },
    methods: {
      submitApplication() {

        this.dataSubmit = {
          idCompetition: this.$route.params.id,
          userId: this.infoUser.id,
          city: this.city || this.aboutRegistrApp.city,
          department: this.aboutRegistrApp.department,
          region: this.region && typeof this.region === 'object' && this.region.name ? this.region.name : this.region,
        };
        this.isSubmitted = true; // Установка флага, что попытка отправки была сделана
        this.$v.dataSubmit.$touch();
        if (this.$v.dataSubmit.$invalid) {
          console.log("Данные не заполнены");
          return;
        }
        this.$store
         .dispatch(actionTypes.submitApplication, this.dataSubmit)
            .then(() => this.$router.push({ path: "/applications" }))
            .catch(error => console.error("Ошибка при отправке: ", error));
      },
      showModalAddSportsmen() {
        this.showAddSportsmen = true;
      },
      showModalAddGroup() {
        this.showAddGroup = true;
      },
      safeCity(item) {
        this.city = item;
      },
      showModalAddCoath() {
        this.showAddCoach = true;
      },
      showModalAddJuj() {
        this.showAddJuj = true;
      },
      safeRegion(item) {
        this.region = item;
      }
    },
    mounted() {

      this.$store
          .dispatch(actionTypes.getInfoAboutRegistrationApp, this.$route.params.id)
          .then((info) => {
            this.city = info.result.city;
            this.region = info.result.region;
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      // получаем всех зарег судей категории
      this.$store
          .dispatch(actionTypesEntered.getJuj)
          .then(() => {})
          .catch((er) => {
            console.log(er, "Error");
          });
  }};
</script>

<style scoped>
/* Ваши стили */
</style>
