<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <form
            @submit.prevent="safeInfoSportsmen"
            id="#modalModificationSportsmen"
          >
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Изменение спортсмена</p>
              </div>
              <div class="modal-scroll-max">
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Фамилия:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    placeholder="Заполните фамилию"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.putData.surname.$error,
                    }"
                    name="surname"
                    :value="item_sportsmen.surname"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.putData.surname.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Имя:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.putData.name.$error,
                    }"
                    placeholder="Заполните имя"
                    name="name"
                    :value="item_sportsmen.name"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.putData.name.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>
                <div class="">
                  <div class="input-wrap">
                    <div class="input-wrap__label">
                      <p class="text-label">Отчество:</p>
                    </div>
                    <input
                        type="text"
                        class="input-light-text"
                        :class="{
                      'error-border-input': isSubmitted && putData.patronymic.$error,
                    }"
                        placeholder="Заполните отчество"
                        name="patronymic"
                        v-model="item_sportsmen.patronymic"
                    />
                    <img
                        src="~@/assets/svg/Error.svg"
                        alt="error"
                        v-if="isSubmitted && $v.putData.patronymic.$error"
                        class="wrapValidationInput__error"
                    />
                  </div>
                </div>
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Дата рождения:</p>
                  </div>
                  <input
                    type="text"
                    name="dateOfBirth"
                    class="input-light-text"
                    placeholder="01.01.2000"
                    v-mask="'##.##.####'"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.putData.dateOfBirth.$error,
                    }"
                    v-model.lazy="item_sportsmen.dateOfBirth"
                    @input="handleInputDate"
                  />
                </div>
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Разряд при наличии:</p>
                  </div>
                  <custom-select-rank
                    v-if="this.selectRank !== null"
                    @input="safeRank"
                    :default="item_sportsmen.rank"
                    :options="this.selectRank"
                  />
                </div>
              </div>
              <div class="modal-body__table">
                <div
                  v-if="isSubmitted && !$v.checkedCoachID.required"
                  class="validation"
                >
                  <p class="validation__text">*Назначьте тренера</p>
                </div>
                <div class="input-wrap__label">
                  <p class="text-label">Тренеры:</p>
                </div>
                <table-coach
                  ref="check"
                  @addCoach="addCoach"
                  @checkedCoach="checkedCoach"
                />
              </div>
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Область/регион:</p>
                  </div>
                  <AppRegionSelect @regionSelected="safeRegion"  :default="item_sportsmen.region"/>
                </div>
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Город:</p>
                  </div>
                  <AppCitySelect :idRegion="region.id"  @citySelected="safeCity"  :default="item_sportsmen.city" />
                </div>
              </div>
            </div>

            </div>
            <div class="modal-footer">
              <div class="modal-footer__button">
                <button class="button">Сохранить</button>
              </div>
              <div class="modal-footer__button">
                <button class="button-transponent" @click="$emit('close')">
                  Отменить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import { actionTypesEntered } from "@/store/modules/enteredData";
import { mapState } from "vuex";
import TableCoach from "../TableInModal/TableCoach.vue";
import CustomSelectRank from "../customSelect/CustomSelectRank.vue";
import { required } from "vuelidate/lib/validators";
import AppRegionSelect from '@/components/customSelect/RegionSelect.vue'
import AppCitySelect from '@/components/customSelect/CitySelect.vue'
export default {
  name: "AppModalModificationSportsmen",
  data() {
    return {
      city:  this.item_sportsmen.city
          ? this.item_sportsmen.city
          : '',
      region:  this.item_sportsmen.region
          ? this.item_sportsmen.region
          : '',
      rank: "",
      selectRegion: ["Новосибирская область"],
      selectCity: ["Новосибирск","Краснообск", "Бердск", "Академгородок"],
      putData: [],
      checkedCoachID: [],
      isSubmitted: false,
    };
  },
  validations: {
    putData: {
      name: {
        required,
      },
      patronymic: {
        // required
      },
      surname: {
        required,
      },
      dateOfBirth: {
        required,
      },
    },
    checkedCoachID: {
      required,
    },
  },
  components: {
    AppCitySelect,
    AppRegionSelect,
    TableCoach,
    CustomSelectRank,
  },
  props: {
    item_sportsmen: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      selectRank: (state) => state.registration.selectRank,
    }),
  },
  methods: {
    checkedCoach(item) {
      this.checkedCoachID = item;
    },
    handleInputDate(event) {
      // Удаляем любые символы кроме цифр и точек.
      const cleanValue = event.target.value.replace(/[^0-9.]/g, '');
      // В данном случае формат должен содержать ровно 10 символов (две цифры для дня, месяца, года и две точки).
      if (cleanValue.length > 10) {
        event.target.value = cleanValue.slice(0, 10);
      } else {
        event.target.value = cleanValue;
      }

      this.item_sportsmen.dateOfBirth = event.target.value;
    },
    safeInfoSportsmen() {
      const formData = new FormData(
        document.getElementById("#modalModificationSportsmen")
      );
      this.putData = [];
      for (let [name, value] of formData) {
        this.putData = { ...this.putData, [name]: value };
      }
      this.putData = {
        ...this.putData,
        region:  this.region && typeof this.region === 'object' && this.region.name ? this.region.name : this.region,
        gender: this.item_sportsmen.gender,
        city: this.city,
        rank: this.rank.id,
        coachId: this.checkedCoachID,
        id: this.item_sportsmen.id,
      };
      this.$v.$touch();
      this.isSubmitted = true;
      if (this.$v.$invalid) {
        return console.log("Данные не заполенены");
      } else {
        //  Изменение спортсмена
        this.$store
          .dispatch(actionTypesEntered.editSportsmen, this.putData)
          .then(() => {
            this.$emit("close");
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
    },
    addCoach() {
      this.$emit("addCoach");
    },
    safeCity(item) {
      console.log(item, "city");
      this.city = item;
    },
    safeRegion(item) {
      console.log(item, "region");
      this.region = item;
    },
    safeRank(item) {
      console.log(item, "rank");
      this.rank = item;
    },
    updateChoiceCheckedCoach() {
      this.$refs.check._data.checkedCoach = this.item_sportsmen.coachId;
    },
    //надо сделать getter?!?
    findRank(selectRank) {
      selectRank.map((item) => {
        if (this.item_sportsmen.rank === item.name) {
          return (this.rank = item);
        }
      });
    },
    //найти выбранные город
    findCity() {
      this.selectCity.map((item) => {
        if (this.item_sportsmen.city === item) {
          return (this.city = item);
        }
      });
    },
    findRegion() {
      this.selectRegion.map((item) => {
        console.log(item);
        console.log(this.item_sportsmen.region);
        if (this.item_sportsmen.region === item) {
          return (this.region = item);
        }
      });
    },
  },
  mounted() {
    this.findCity();
    this.findRegion();
    //Получение всех тренеров
    this.$store
      .dispatch(actionTypesEntered.getCoaches)
      .then(() => {
        this.updateChoiceCheckedCoach();
      })
      .catch((er) => {
        console.log("Ошибка", er);
      });
    //получение все разрядов
    this.$store
      .dispatch(actionTypes.getRank)
      .then((response) => {
        console.log(response);
        this.findRank(response.result);
      })
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
