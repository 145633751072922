<template>
  <div style="width: 100%">
    <div class="accordion" :class="{ 'is-open': isOpen }">
      <div
        class="accordion-header-placeholder"
        ref="excludedElement"
        :style="{ background: this.color }"
        @click="toggleAccordion"
        v-if="selected === this.default"
      >
        {{ selected }}
      </div>
      <div class="accordion-body items" ref="body">
        <div
          v-for="(option, i) of options"
          :key="i"
          @click="safeSelect(option), toggleAccordion()"
        >
          {{ option.surname }} {{ option.name }} {{ option.patronymic }} /
          {{ option.category }}
        </div>
        <div class="add" @click="showModalAddJuj(), toggleAccordion()">
          <img src="../../assets/svg/plus.svg" alt="plus" />
          <p class="add-text">Добавить судью</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSelecJudges",
  data() {
    return {
      isOpen: false,
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      selectWithCateg: {},
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },

    color: {
      type: String,
    },
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
  },
  methods: {
    safeSelect(option) {
      this.selected = option;
      this.$emit("input", this.selected);
      this.selected = this.default;
    },
    showModalAddJuj() {
      this.$emit("showModalAddJuj");
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.animateOpen();
      } else {
        this.animateClose();
      }
    },
    animateOpen() {
      const body = this.$refs.body;
      const height = body.scrollHeight;
      body.style.height = "0";
      setTimeout(() => {
        body.style.height = `${height}px`;
      }, 5);
    },
    animateClose() {
      const body = this.$refs.body;
      body.style.height = `${body.scrollHeight}px`;
      setTimeout(() => {
        body.style.height = "0";
      }, 5);
    },
    handleClickOutside(event) {
      if (this.isOpen && this.$refs.excludedElement && !this.$refs.excludedElement.contains(event.target)) {
        this.toggleAccordion()
        this.isOpen = false;
      }
    },
  },
  // При уничтожении компонента убедитесь, что вы удалили обработчик событий,
  // чтобы избежать утечек памяти или других потенциальных проблем.
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style></style>
