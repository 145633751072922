<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :style="dynamicWidthStyle">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>

          <div class="modal-body">
            <div class="modal-body__title">
              <p class="modal__title">Заявка</p>
            </div>

            <div class="modal-body__table modal-scroll-max-app">
              <div class="input-wrap__label">
                <div class="tab-competition">
                  <div
                    class="tab-competition__block"
                    v-if="this.item_app.sportsmans.length"
                    :class="[isActiveBlockInd ? 'tab-active-dark' : 'tab-noactive']"
                    @click="switchTabsClass('individ')"
                  >
                    <p class="tab-competition__text">Инд.выступления</p>
                  </div>
                  <div
                    class="tab-competition__block"
                    v-if="this.item_app.groups.length"
                    :class="[
                      isActiveBlockGroup ? 'tab-active-dark' : 'tab-noactive',
                    ]"
                    @click="switchTabsClass('group')"
                  >
                    <p class="tab-competition__text">Группы</p>
                  </div>
                  <div
                    class="tab-competition__block"
                    v-if="this.item_app.judges.length"
                    :class="[
                      isActiveBlockJudge ? 'tab-active-dark' : 'tab-noactive',
                    ]"
                    @click="switchTabsClass('judge')"
                  >
                    <p class="tab-competition__text">Судьи</p>
                  </div>
                </div>
              </div>
          <div class="modal-body__table-wrap">
              <table-app-sportsmen
                :allSportsmen="item_app"
                v-if="this.isActiveBlockInd"
              />

              <table-app-group
                :allSportsmen="item_app"
                v-if="this.isActiveBlockGroup"
              />
              <table-app-judge
                :allSportsmen="item_app"
                v-if="this.isActiveBlockJudge"
              />
            </div>
            </div>
          </div>
          </div>

        </div>
      </div>
  </transition>
</template>

<script>
import TableAppJudge from "../TableInModal/TableAppJudge.vue";
import TableAppGroup from "../TableInModal/TableAppGroup.vue";

import TableAppSportsmen from "../TableInModal/TableAppSportsmen.vue";

export default {
  name: "AppModalApplication",
  data() {
    return {
      isActiveBlockInd: true,
      isActiveBlockGroup: false,
      isActiveBlockJudge: false,
      windowWidth: window.innerWidth // Инициализируйте с текущей шириной окна
    };
  },
  props: {
    item_app: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    TableAppSportsmen,
    TableAppGroup,
    TableAppJudge,
  },
  computed: {
    dynamicWidthStyle() {
      return {
        width: `${this.windowWidth * 0.9}px` // Вычисляем 80% от ширины окна
      };
    },
  },
  methods: {
    addCoach() {
      this.$emit("addCoach");
    },
    switchTabsClass(tab) {
      if (tab === "individ") {
        this.isActiveBlockInd = true;
        this.isActiveBlockGroup = false;
        this.isActiveBlockJudge = false;
      } else if (tab === "group") {
        this.isActiveBlockInd = false;
        this.isActiveBlockGroup = true;
        this.isActiveBlockJudge = false;
      } else if (tab === "judge") {
        this.isActiveBlockInd = false;
        this.isActiveBlockGroup = false;
        this.isActiveBlockJudge = true;
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth; // Обновляем ширину при изменении размера окна
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize); // Слушаем изменения размера окна
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize); // Очищаем слушателя при уничтожении компонента
  },
};
</script>

<style></style>
