<template>
  <!-- Отображение собранных заявок -->
  <!-- this.aboutRegistrApp.judges -->
  <div class="search">
    <item-app-juj
      v-for="(item, index) in this.aboutRegistrApp.judges"
      :key="index"
      @deleteItemJuj="deleteItemJuj"
      :item_juj="item"
      :index="index"
    />

    <!-- Создание собранных заявок -->
    <div
      class="search__wrap-block-select"
      v-if="(this.aboutRegistrApp.status !== 'CLOSE_EDIT_APPLICATION')"
    >
      <div class="search__add-coach">
        <custom-select-app-juj
          @showModalAddJuj="showModalAddJuj"
          @input="safeJujPeople"
          :default="this.defaultCoach"
          :options="this.allJuj"
        />
      </div>
    </div>
        <div class="validation" v-if="this.validationErrorsAddJuj !== null">
          <p class="validation__text">{{ validationErrorsAddJuj.message }}</p>
        </div>
    <div class="about-application" v-if="this.aboutRegistrApp">
      <p class="about-application__text">
        Итого в заявке:
        {{ this.aboutRegistrApp.judges.length }}
        судей
      </p>
    </div>
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import { mapState } from "vuex";

// import ItemAppIndividual from "./ItemAppIndividual.vue";
import CustomSelectAppJuj from "../customSelect/CustomSelectAppJuj.vue";
import ItemAppJuj from "./ItemAppJuj.vue";
export default {
  name: "AppJujApplication",
  data() {
    return {
      defaultCategory: "Категория группы",
      defaultCoach: "Выберите судью из списка ",

      listAddJuj: [],
      dataIdToIndSportsmen: {},
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      individualCategories: (state) => state.registration.individualCategories,
      individualSportsmens: (state) => state.registration.individualSportsmens,
      aboutRegistrApp: (state) => state.registration.aboutRegistrApp,
      allJuj: (state) => state.enteredData.allJuj,
      validationErrorsAddJuj: (state) => state.registration.validationErrorsAddJuj,
    }),
  },
  components: {
    // ItemAppIndividual,
    CustomSelectAppJuj,
    ItemAppJuj,
  },
  methods: {
    deleteItemJuj(item) {
      console.log(item);
      this.aboutRegistrApp.judges.splice(
        0,
        this.aboutRegistrApp.judges.length,
        ...this.aboutRegistrApp.judges.filter((n) => n.id !== item.id)
      );
      this.$store
        .dispatch(actionTypes.deleteJujApp, {
          idCompetition: this.$route.params.id,
          appIdJudges: item.id,
          // idSportsmen: item.sportsmen.id,
        })
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    safeJujPeople(item) {
      this.listAddJuj.push(item);
      this.$store
        .dispatch(actionTypes.addJujApp, {
          idCompetition: this.$route.params.id,
          idJudge: item.id,
        })
        .then(() => {})
        .catch((er) => {
          console.log(er, "Error");
        });
    },
    showModalAddJuj() {
      this.$emit("showModalAddJuj");
    },
  },
};
</script>

<style></style>
