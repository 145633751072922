var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"modal-mask",style:({ 'z-index': 900 })},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal__cross",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("../../assets/svg/cross.svg"),"alt":"cross"}})])]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.safeInfoSportsmen.apply(null, arguments)}}},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-body__title"},[_c('p',{staticClass:"modal__title"},[_vm._v("Добавление групп")])]),(_vm.validationErrorsGroup)?_c('ValidationErrors',{attrs:{"validation-errors":_vm.validationErrorsGroup.message}}):_vm._e(),_c('div',{staticClass:"modal-scroll-max"},[_c('div',{staticClass:"input-wrap__column"},[_c('div',{staticClass:"input-wrap__label-with-input-column"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Название группы:")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"input-light-text",class:{
                      'error-border-input': _vm.isSubmitted && _vm.$v.name.$error,
                    },attrs:{"type":"text","placeholder":"Заполните название группы","name":"name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.name.$error)?_c('img',{staticClass:"wrapValidationInput__error",attrs:{"src":require("@/assets/svg/Error.svg"),"alt":"error"}}):_vm._e()])]),_c('div',{staticClass:"modal-body__table"},[(_vm.isSubmitted && !_vm.$v.checkedCoachID.required)?_c('div',{staticClass:"validation"},[_c('p',{staticClass:"validation__text"},[_vm._v("*Назначьте тренера")])]):_vm._e(),_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Тренеры:")])]),_c('table-coach',{ref:"check",on:{"addCoach":_vm.addCoach,"checkedCoach":_vm.checkedCoach}})],1),(_vm.$v.selectPeople.$error && !_vm.$v.selectPeople.minLength)?_c('div',{staticClass:"message-about-empty empty"},[_c('p',{staticClass:"message-about-empty__text"},[_vm._v(" Добавлено менее 5 спортсменов в группу!")])]):_vm._e(),_c('div',{staticClass:"input-wrap"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Состав группы")])]),(this.selectPeople.length !== 0)?_c('div',{staticClass:"group-list-in-modal"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"empty_th"}),_c('th',{staticClass:"th__name"},[_vm._v("Ф.И.О.")]),_c('th',{staticClass:"th__years"},[_vm._v("Год р.")]),_c('th',{staticClass:"th__section"},[_vm._v("Разряд")]),_c('th',{staticClass:"th__trainer"},[_vm._v("Тренеры")])])]),_c('tbody',_vm._l((this.selectPeople),function(sportsmen,i){return _c('item-table-sportsmen',{key:i,attrs:{"item_sportsmen":sportsmen},on:{"deleteSportsmen":_vm.deleteSportsmen}})}),1)])]):_vm._e(),_c('custom-select-app-people',{class:{
                    'error-border-input': _vm.isSubmitted && _vm.$v.selectPeople.$error,
                  },attrs:{"default":this.defaultSportsmen,"options":this.allSportsmens,"color":`#ffffff`},on:{"input":_vm.safePeople,"showModalAddSportsmen":_vm.addSportsmen}})],1),_c('div',{staticClass:"input-wrap__row"},[_c('div',{staticClass:"input-wrap__label-with-input-row"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Область/регион:")])]),_c('AppRegionSelect',{class:{
                    'error-border-input': _vm.isSubmitted && _vm.$v.region.$error,
                  },on:{"regionSelected":_vm.safeRegion}})],1),(this.region)?_c('div',{staticClass:"input-wrap__label-with-input-row"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Город:")])]),_c('AppCitySelect',{class:{
                    'error-border-input': _vm.isSubmitted && _vm.$v.city.$error,
                  },attrs:{"idRegion":this.region.id},on:{"citySelected":_vm.safeCity}})],1):_vm._e()]),(_vm.isSubmitted && this.$v.$invalid)?_c('div',{staticClass:"message-about-empty empty"},[_c('p',{staticClass:"message-about-empty__text"},[_vm._v("Заполните все данные!")])]):_vm._e()])],1),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"modal-footer__button"},[_c('button',{staticClass:"button"},[_vm._v("Сохранить")])]),_c('div',{staticClass:"modal-footer__button"},[_c('button',{staticClass:"button-transponent",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Отменить ")])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }