<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask" :style="{ 'z-index': 900 }">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <form @submit.prevent="safeInfoSportsmen">
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Добавление групп</p>
              </div>
              <ValidationErrors
                  v-if="validationErrorsGroup"
                  :validation-errors="validationErrorsGroup.message"
              />
              <div class="modal-scroll-max">

              <div class="input-wrap__column">
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="text-label">Название группы:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input': isSubmitted && $v.name.$error,
                    }"
                    placeholder="Заполните название группы"
                    name="name"
                    v-model="name"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.name.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>

              <div class="modal-body__table">
                <div
                  v-if="isSubmitted && !$v.checkedCoachID.required"
                  class="validation"
                >
                  <p class="validation__text">*Назначьте тренера</p>
                </div>
                <div class="input-wrap__label">
                  <p class="text-label">Тренеры:</p>
                </div>
                <table-coach
                  ref="check"
                  @addCoach="addCoach"
                  @checkedCoach="checkedCoach"
                />
              </div>

                <!-- Блок для отображения ошибки длины массива -->
                <div v-if="$v.selectPeople.$error && !$v.selectPeople.minLength"  class="message-about-empty empty">
                  <p class="message-about-empty__text"> Добавлено менее 5 спортсменов в группу!</p>
                </div>

              <div class="input-wrap">
                <div class="input-wrap__label">
                  <p class="text-label">Состав группы</p>
                </div>

                <div
                  class="group-list-in-modal"
                  v-if="this.selectPeople.length !== 0"
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="empty_th"></th>
                        <th class="th__name">Ф.И.О.</th>
                        <th class="th__years">Год р.</th>
                        <th class="th__section">Разряд</th>
                        <th class="th__trainer">Тренеры</th>
                      </tr>
                    </thead>
                    <tbody>
                      <item-table-sportsmen
                        v-for="(sportsmen, i) in this.selectPeople"
                        :key="i"
                        :item_sportsmen="sportsmen"
                        @deleteSportsmen="deleteSportsmen"
                      />
                      <!-- @editSportsmen="editSportsmen" -->
                    </tbody>
                  </table>
                </div>
<!--                <AppAddPeopleSelect windowWidth:="isMobile"  @input="safePeople"-->
<!--                                    :class="{-->
<!--                    'error-border-input': isSubmitted && $v.selectPeople.$error,-->
<!--                  }"-->
<!--                                    @showModalAddSportsmen="addSportsmen"-->
<!--                                    :default="this.defaultSportsmen"-->
<!--                                    :options="this.allSportsmens"-->
<!--                                    :color="`#ffffff`" :windowWidth="isMobile"/>-->
                <custom-select-app-people
                  @input="safePeople"
                  :class="{
                    'error-border-input': isSubmitted && $v.selectPeople.$error,
                  }"
                  @showModalAddSportsmen="addSportsmen"
                  :default="this.defaultSportsmen"
                  :options="this.allSportsmens"
                  :color="`#ffffff`"
                />
              </div>

              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Область/регион:</p>
                  </div>
                  <AppRegionSelect @regionSelected="safeRegion"  :class="{
                    'error-border-input': isSubmitted && $v.region.$error,
                  }"/>
                </div>
                <div class="input-wrap__label-with-input-row" v-if="this.region">
                  <div class="input-wrap__label">
                    <p class="text-label">Город:</p>
                  </div>
                  <AppCitySelect :idRegion="this.region.id"  @citySelected="safeCity"  :class="{
                    'error-border-input': isSubmitted && $v.city.$error,
                  }" />
                </div>
              </div>
                <div
                    class="message-about-empty empty"
                    v-if="isSubmitted && this.$v.$invalid"
                >
                  <p class="message-about-empty__text">Заполните все данные!</p>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <div class="modal-footer__button">
                <button class="button">Сохранить</button>
              </div>
              <div class="modal-footer__button">
                <button
                    type="button"
                    class="button-transponent"
                    @click="$emit('close')"
                >
                  Отменить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import { actionTypesEntered } from "@/store/modules/enteredData";
import { mapState } from "vuex";
import TableCoach from "../TableInModal/TableCoach.vue";
import CustomSelectAppPeople from "../customSelect/CustomSelectAppPeople.vue";
import { required, minLength } from "vuelidate/lib/validators";
import ItemTableSportsmen from "../Table/ItemTableSportsmen.vue";
import ValidationErrors from '@/components/ValidationErrors.vue'
import AppRegionSelect from '@/components/customSelect/RegionSelect.vue'
import AppCitySelect from '@/components/customSelect/CitySelect.vue'
// import AppAddPeopleSelect from '@/components/customSelect/AddPeopleSelect.vue'
export default {
  name: "AppModalAddGroup",
  data() {
    return {
      name: "",

      region: "",
      city: "",
      rank: "",
      checkedCoachID: [],
      selectPeople: [],
      sportsmenId: [],
      selectRegion: ["Новосибирская область"],
      selectCity: ["Новосибирск","Краснообск", "Бердск", "Академгородок"],
      defaultRegion: "Выберите регион",
      defaultCity: "Выберите город",
      defaultSportsmen: "Добавление спортсмена",
      isSubmitted: false,
      windowWidth: window.innerWidth, // Инициализируем начальное значение
    };
  },
  validations: {
    name: {
      required,
    },
    region: {
      required,
    },

    city: {
      required,
    },
    checkedCoachID: {
      required,
    },
    selectPeople: {
      required,
      minLength: minLength(5)
    },
  },
  components: {
    // AppAddPeopleSelect,
    AppCitySelect,
    AppRegionSelect,
    ValidationErrors,
    TableCoach,
    CustomSelectAppPeople,
    //  SelectWithCheckPeople,
    ItemTableSportsmen,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      selectRank: (state) => state.registration.selectRank,
      allSportsmens: (state) => state.enteredData.allSportsmens,
      validationErrorsGroup: (state) => state.enteredData.validationErrorsGroup
      // coaches: (state) => state.registration.coaches,
    }),
    isMobile() {
      return this.windowWidth <= 1024; // Логика определения мобильного режима
    },

  },
  methods: {
    checkedCoach(item) {
      this.checkedCoachID = item;
    },
    safePeople(item) {
      this.selectPeople.push(item);
    },
    deleteSportsmen(item) {
      this.selectPeople.splice(
        0,
        this.selectPeople.length,
        ...this.selectPeople.filter((n) => n.id !== item.id)
      );
    },

    safeInfoSportsmen() {
      this.selectPeople.map((item) => {
        this.sportsmenId.push(item.id);
      });
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return console.log("Данные не заполенены");
      } else {
        this.$store
          .dispatch(actionTypesEntered.registrGroup, {
            name: this.name,
            region: this.region.name,
            city: this.city,
            coachId: this.checkedCoachID,
            sportsmenId: this.sportsmenId,
          })
          .then(() => {
            //получение всех спортсменов
            this.$emit("close");
            this.name = "";
            this.region = "";
            this.rank = "";
            this.checkedCoachID = [];
            this.selectPeople = [];
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
    },
    addCoach() {
      this.$emit("addCoach");
    },
    addSportsmen() {
      this.$emit("addSportsmen");
    },
    safeCity(item) {
      console.log(item, "city");
      this.city = item;
    },
    safeRegion(item) {
      console.log(item, "region");
      this.region = item;
    },
    safeRank(item) {
      console.log(item, "rank");
      this.rank = item;
    },
  },
  mounted() {
    //Получение всех тренеров
    this.$store
      .dispatch(actionTypesEntered.getCoaches)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
    //получение все разрядов
    this.$store
      .dispatch(actionTypes.getRank)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
