<template>
  <!-- Отображение собранных заявок -->
  <div class="search">
    <template v-if="this.aboutRegistrApp">
      <item-app-individual
        v-for="(item, index) in this.aboutRegistrApp.sportsmans"
        :key="index"
        @deleteItemAppSportsmen="deleteItemAppSportsmen"
        :item_sport="item"
        :index="index"
      />
    </template>
    <!-- Создание собранных заявок -->
    <div
      class="search__wrap-block-select"
      v-if="(this.aboutRegistrApp.status !== 'CLOSE_EDIT_APPLICATION')"
    >
      <div class="search__select-categ">
        <select-with-animate-categ
          @input="safeIndividualCateg"
          :default="this.defaultCategory"
          :options="this.individualCategories"
          v-if="this.individualCategories !== null"
          ref="categSelect"
        />
      </div>
      <div class="search__add-sportsmen" v-if="safeIndividualCategories !== ''">
        <AppAddPeopleSelect   @showModalAddSportsmen="showModalAddSportsmen"
                              @input="safeIndividualPeople"
                              :selectIndCateg="this.safeIndividualCategories"
                              :default="this.defaultIndividualSporsmen"
                              :options="this.individualSportsmens"
        :windowWidth="isMobile"/>
      </div>
      <div class="search__add-sportsmen-text" v-else>
        <p class="text-succses">Для выбора спортсменов выберите категорию</p>
      </div>
    </div>
    <div class="validation" v-if="this.validationErrorsAddSportsmen !== null">
      <p class="validation__text">{{ validationErrorsAddSportsmen.message }}</p>
    </div>
    <div class="about-application" v-if="this.aboutRegistrApp">
      <p class="about-application__text">
        Итого в заявке:
        {{ this.aboutRegistrApp.sportsmans.length }} спортсменов
      </p>
    </div>
    <!-- Создание собранных заявок -->
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import { mapState } from "vuex";
import SelectWithAnimateCateg from "../customSelect/SelectWithAnimateCateg.vue";
// import CustomSelectAppPeople from "../customSelect/CustomSelectAppPeople.vue";

import ItemAppIndividual from "./ItemAppIndividual.vue";
import AppAddPeopleSelect from '@/components/customSelect/AddPeopleSelect.vue'
export default {
  name: "AppIndividualApplication",
  data() {
    return {
      defaultCategory: "Выберите категорию",
      defaultIndividualSporsmen: "Выберите спортсмена",
      safeIndividualCategories: "",
      dataIdToIndSportsmen: {},
      windowWidth: window.innerWidth, // Инициализируем начальное значение
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      individualCategories: (state) => state.registration.individualCategories,
      individualSportsmens: (state) => state.registration.individualSportsmens,
      aboutRegistrApp: (state) => state.registration.aboutRegistrApp,
      validationErrorsAddSportsmen: (state) => state.registration.validationErrorsAddSportsmen,
    }),
    isMobile() {
      return this.windowWidth <= 1024; // Логика определения мобильного режима
    },
  },
  components: {
    AppAddPeopleSelect,
    SelectWithAnimateCateg,
    // CustomSelectAppPeople,
    ItemAppIndividual,
  },
  methods: {
    deleteItemAppSportsmen(item) {
      this.aboutRegistrApp.sportsmans.splice(
        0,
        this.aboutRegistrApp.sportsmans.length,
        ...this.aboutRegistrApp.sportsmans.filter(
          (n) => n.idApplication !== item.idApplication
        )
      );
      this.$store
        .dispatch(actionTypes.deleteSportsmenApp, {
          idCompetition: this.$route.params.id,
          idSportsmen: item.sportsmen.id,
        })
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    safeIndividualPeople(item) {
      this.$store
        .dispatch(actionTypes.addSportsmenApp, {
          idCompetition: this.$route.params.id,
          idSportsmen: item.sportsmen.id,
          idCategory: item.categ.id,
        })
        .then(() => {
          this.safeIndividualCategories = '';
        })
        .catch((er) => {
          console.log(er, "Error");
        });
      this.$refs.categSelect.updateData();
    },
    showModalAddSportsmen() {
      //ОТЧИСТКА ВЫБОРА КАТЕГОРИИ
      this.$refs.categSelect.updateData();
      this.$emit("showModalAddSportsmen");
    },
    safeIndividualCateg(item) {
      this.safeIndividualCategories = item;
      // получение спортсменов по id категории
      this.dataIdToIndSportsmen = {
        categId: item.id,
        idCompetition: this.$route.params.id,
      };
      this.$store
        .dispatch(
          actionTypes.getIndividualSportsmens,
          this.dataIdToIndSportsmen
        )
        .then(() => {
          // this.safeIndividualCategories = '';
        })
        .catch((er) => {
          console.log(er, "Error");
        });
    },
  },
  mounted() {},
};
</script>

<style></style>
