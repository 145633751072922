import regCompAPi from "@/api/regComp.js";
import { setItem, getItem } from "@/helpers/persistanceStorage";

const state = {
  isLoading: false, //ожидание запроса
  isLoadingPhoto: false,
  validationErrors: null,
  individualCategoryItem: null,
  performedTypes: null,
  performedTypesGroup: null,
  ranksGroup: null,
  ranks: null,
  competitionId: getItem("competitionId") || null,
  userCompetition: null,
  draftCompetition: [],
  finishCompetition: [],
  competitionIndividualCateg: null,
  competitionGroupCateg: null,
  competitionItemInfo: null,
};

export const mutationTypes = {
  competitionsRegStart: "[registrationCategory] competitionsRegStart",
  competitionsRegSuccess: "[registrationCategory] competitionsRegSuccess",
  competitionsRegFailure: "[registrationCategory] competitionsRegFailure",

  individualCategoryStart: "[registrationCategory] individualCategoryStart",
  individualCategorySuccess: "[registrationCategory] individualCategorySuccess",
  individualCategoryFailure: "[registrationCategory] individualCategoryFailure",

  allUserCompetitionStart: "[registrationCategory] allUserCompetitionStart",
  allUserCompetitionSuccess: "[registrationCategory] allUserCompetitionSuccess",
  allUserCompetitionFailure: "[registrationCategory] allUserCompetitionFailure",

  deleteCompetitionStart: "[registrationCategory]  deleteCompetitionStart",
  deleteCompetitionSuccess: "[registrationCategory]  deleteCompetitionSuccess",
  deleteCompetitionFailure: "[registrationCategory]  deleteCompetitionFailure",

  addPhotoStart: "[registrationCategory]  addPhotoStart",
  addPhotoSuccess: "[registrationCategory]  addPhotoSuccess",
  addPhotoFailure: "[registrationCategory]  addPhotoFailure",

  competitionsRegFirstStart: "[registrationCategory]competitionsRegFirstStart",
  competitionsRegFirstSuccess:
    "[registrationCategory] competitionsRegFirstSuccess",
  competitionsRegFirstFailure:
    "[registrationCategory] competitionsRegFirstFailure",

  competitionsRegSecondStepStart:
    "[registrationCategory] competitionsRegSecondStepStart",
  competitionsRegSecondStepSuccess:
    "[registrationCategory] competitionsRegSecondStepSuccess",
  competitionsRegSecondStepFailure:
    "[registrationCategory] competitionsRegSecondStepFailure",
  competitionsDeleteSecondStepStart:
    "[registrationCategory] competitionsDeleteSecondStepStart",
  competitionsDeleteSecondStepSuccess:
    "[registrationCategory] competitionsDeleteSecondStepSuccess",
  competitionsDeleteSecondStepFailure:
    "[registrationCategory] competitionsDeleteSecondStepFailure",

  competitionAllIndividualStart:
    "[registrationCategory]  competitionAllIndividualStart",
  competitionAllIndividualSuccess:
    "[registrationCategory]  competitionAllIndividualSuccess",
  competitionAllIndividualFailure:
    "[registrationCategory]  competitionAllIndividualFailure",
  getCompetitionItemStart: "[registrationCategory]  getCompetitionItemStart",
  getCompetitionItemSuccess:
    "[registrationCategory]  getCompetitionItemSuccess",
  getCompetitionItemFailure:
    "[registrationCategory]  getCompetitionItemFailure",

  competitionsRegTrirdStepStart:
    "[registrationCategory]  competitionsRegTrirdStepStart",
  competitionsRegTrirdStepSuccess:
    "[registrationCategory]  competitionsRegTrirdStepSuccess",
  competitionsRegTrirdStepFailure:
    "[registrationCategory]  competitionsRegTrirdStepFailure",

  competitionsDeleteTrirdStepStart:
    "[registrationCategory]  competitionsDeleteTrirdStepStart",
  competitionsDeleteTrirdStepSuccess:
    "[registrationCategory]  competitionsDeleteTrirdStepSuccess",
  competitionsDeleteTrirdStepFailure:
    "[registrationCategory]  competitionsDeleteTrirdStepFailure",

  groupCategoryStart: "[registrationCategory]  groupCategoryStart",
  groupCategorySuccess: "[registrationCategory]  groupCategorySuccess",
  groupCategoryFailure: "[registrationCategory]  groupCategoryFailure",

  getGroupCategoryDraftStart:
    "[registrationCategory]  getGroupCategoryDraftStart",
  getGroupCategoryDraftSuccess:
    "[registrationCategory]  getGroupCategoryDraftSuccess",
  getGroupCategoryDraftFailure:
    "[registrationCategory]  getGroupCategoryDraftFailure",

  endCompetitionStart: "[registrationCategory] endCompetitionStart",
  endCompetitionSuccess: "[registrationCategory]  endCompetitionStuccess",
  endCompetitionFailure: "[registrationCategory]  endCompetitionFailure",
};
export const actionTypes = {
  individualCategory: "[registrationCategory]  individualCategory",
  competitionsReg: "[registrationCategory]  competitionsReg",
  allUserCompetition: "[registrationCategory] allUserCompetition",
  deleteCompetition: "[registrationCategory]  deleteCompetition",
  addPhoto: "[registrationCategory] addPhoto",
  competitionsRegFirst: "[registrationCategory] competitionsRegFirst",
  competitionsRegSecondStep: "[registrationCategory] competitionsRegSecondStep",
  competitionsDeleteSecondStep:
    "[registrationCategory] competitionsDeleteSecondStep",
  competitionAllIndividual: " [registrationCategory] competitionAllIndividual",
  getCompetitionItem: " [registrationCategory] getCompetitionItem",
  competitionsRegTrirdStep: "[registrationCategory] competitionsRegTrirdStep",
  competitionsDeleteTrirdStep:
    "[registrationCategory] competitionsDeleteTrirdStep",
  groupCategory: "[registrationCategory] groupCategory",
  getGroupCategoryDraft: "[registrationCategory] getGroupCategoryDraft",
  endCompetition: "registrationCategory] endCompetition",
};

const mutations = {
  [mutationTypes.individualCategoryStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.individualCategorySuccess](state, categ) {
    state.isLoading = false;

    state.performedTypes = categ.result.performedTypes;
    state.ranks = categ.result.ranks;
  },
  [mutationTypes.individualCategoryFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  [mutationTypes.competitionsRegStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.competitionsRegSuccess](state, competition) {
    state.isLoading = false;
    state.competitionId = competition.result;
    setItem("competitionId", competition.result);
  },
  [mutationTypes.competitionsRegFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  [mutationTypes.allUserCompetitionStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.allUserCompetitionSuccess](state, competition) {
    state.isLoading = false;
    state.draftCompetition = [];
    state.finishCompetition = [];
    state.userCompetition = competition.result;
    competition.result.map((item) => {
      // console.log(item);
      if (item.isFinished) {
        state.finishCompetition.push(item);
      } else {
        state.draftCompetition.push(item);
      }
    });
  },
  [mutationTypes.allUserCompetitionFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  [mutationTypes.deleteCompetitionStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.deleteCompetitionSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteCompetitionFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // addPhoto
  [mutationTypes.addPhotoStart](state) {
    state.isLoadingPhoto = true;
  },
  [mutationTypes.addPhotoSuccess](state) {
    state.isLoadingPhoto = false;
  },
  [mutationTypes.addPhotoFailure](state, payload) {
    state.isLoadingPhoto = false;
    state.validationErrors = payload;
  },
  [mutationTypes.competitionsRegFirstStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.competitionsRegFirstSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.competitionsRegFirstFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  [mutationTypes.competitionsRegSecondStepStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.competitionsRegSecondStepSuccess](state, categ) {
    state.isLoading = false;
    state.competitionIndividualCateg = categ.result;
  },
  [mutationTypes.competitionsRegSecondStepFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  [mutationTypes.competitionsDeleteSecondStepStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.competitionsDeleteSecondStepSuccess](state, categ) {
    state.isLoading = false;
    state.competitionIndividualCateg = categ.result;
  },
  [mutationTypes.competitionsDeleteSecondStepFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // competitionAllIndividual
  [mutationTypes.competitionAllIndividualStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.competitionAllIndividualSuccess](state, categ) {
    state.isLoading = false;
    state.competitionIndividualCateg = categ.result;
  },
  [mutationTypes.competitionAllIndividualFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // getCompetitionItem
  [mutationTypes.getCompetitionItemStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCompetitionItemSuccess](state, categ) {
    state.isLoading = false;
    state.competitionItemInfo = categ.result;
  },
  [mutationTypes.getCompetitionItemFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // competitionsRegTrirdStep
  [mutationTypes.competitionsRegTrirdStepStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.competitionsRegTrirdStepSuccess](state, categ) {
    state.isLoading = false;
    state.competitionGroupCateg = categ.result;
  },
  [mutationTypes.competitionsRegTrirdStepFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // competitionsDeleteTrirdStep
  [mutationTypes.competitionsDeleteTrirdStepStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.competitionsDeleteTrirdStepSuccess](state, categ) {
    state.isLoading = false;
    state.competitionGroupCateg = categ.result;
  },
  [mutationTypes.competitionsDeleteTrirdStepFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // groupCategory
  [mutationTypes.groupCategoryStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.groupCategorySuccess](state, categ) {
    state.isLoading = false;
    state.performedTypesGroup = categ.result.performedTypes;
    state.ranksGroup = categ.result.ranks;
  },
  [mutationTypes.groupCategoryFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // getGroupCategoryDraft
  [mutationTypes.getGroupCategoryDraftStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getGroupCategoryDraftSuccess](state, categ) {
    state.isLoading = false;
    state.competitionGroupCateg = categ.result;
  },
  [mutationTypes.getGroupCategoryDraftFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // endCompetition
  [mutationTypes.endCompetitionStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.endCompetitionSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.endCompetitionFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  //Получение возможных индивидуальных категорий
  [actionTypes.individualCategory](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.individualCategoryStart);
      regCompAPi
        .individualCategory()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.individualCategorySuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.individualCategoryFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.individualCategoryFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //Регистрация соревнований(получение id)
  [actionTypes.competitionsReg](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.competitionsRegStart);
      regCompAPi
        .competitionsReg()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.competitionsRegSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.competitionsRegFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.competitionsRegFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //Получение всех соревнований юзера
  [actionTypes.allUserCompetition](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.allUserCompetitionStart);
      regCompAPi
        .allUserCompetition()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.allUserCompetitionSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.allUserCompetitionFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.allUserCompetitionFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //Удаление соревнования
  [actionTypes.deleteCompetition](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteCompetitionStart);
      regCompAPi
        .deleteCompetition(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.deleteCompetitionSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.deleteCompetitionFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.deleteCompetitionFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // Загрузка фотографии(постера)
  [actionTypes.addPhoto](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addPhotoStart);
      regCompAPi
        .addPhoto(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.addPhotoSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.addPhotoFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.addPhotoFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //Первый этап регистрации
  [actionTypes.competitionsRegFirst](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.competitionsRegFirstStart);
      regCompAPi
        .competitionsRegFirst(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.competitionsRegFirstSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.competitionsRegFirstFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.competitionsRegFirstFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //Получение информации из первого этапа
  // getCompetitionItemS
  [actionTypes.getCompetitionItem](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCompetitionItemStart);
      regCompAPi
        .getCompetitionItem(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getCompetitionItemSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getCompetitionItemFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.competitionsRegFirstFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //Второй этап регистрации
  [actionTypes.competitionsRegSecondStep](context, creadentilas) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.competitionsRegSecondStepStart);
      regCompAPi
        .competitionsRegSecondStep(creadentilas)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.competitionsRegSecondStepSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.competitionsRegSecondStepFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.competitionsRegSecondStepFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //Второй этап регистрации удаление категорий
  [actionTypes.competitionsDeleteSecondStep](context, creadentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.competitionsDeleteSecondStepStart);
      regCompAPi
        .competitionsDeleteSecondStep(creadentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.competitionsDeleteSecondStepSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.competitionsDeleteSecondStepFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(
            mutationTypes.competitionsDeleteSecondStepFailure,
            err
          );
          console.log("ошибка", err);
        });
    });
  },
  //Второй этап регистрации получение категорий в черновике
  [actionTypes.competitionAllIndividual](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.competitionAllIndividualStart);
      regCompAPi
        .competitionAllIndividual(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.competitionAllIndividualSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.competitionAllIndividualFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.competitionAllIndividualFailure, err);
          console.log("ошибка", err);
        });
    });
  },

  //Третий этап регистрация категорийssss

  [actionTypes.competitionsRegTrirdStep](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.competitionsRegTrirdStepStart);
      regCompAPi
        .competitionsRegTrirdStep(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.competitionsRegTrirdStepSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.competitionsRegTrirdStepFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.competitionsRegTrirdStepFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //Третий этап удалегие зареганных категорий

  [actionTypes.competitionsDeleteTrirdStep](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.competitionsDeleteTrirdStepStart);
      regCompAPi
        .competitionsDeleteTrirdStep(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.competitionsDeleteTrirdStepSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.competitionsDeleteTrirdStepFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.competitionsDeleteTrirdStepFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //Третий этап получение всех возможных категорий групповых(РАЗРЯДЫ И НОМИНАЦИИ)

  [actionTypes.groupCategory](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.groupCategoryStart);
      regCompAPi
        .groupCategory()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.groupCategorySuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.groupCategoryFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.groupCategoryFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // Третий этап Подгрузка всех ныне зарегистрированных категорий в случае черновика

  [actionTypes.getGroupCategoryDraft](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getGroupCategoryDraftStart);
      regCompAPi
        .getGroupCategoryDraft(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getGroupCategoryDraftSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getGroupCategoryDraftFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getGroupCategoryDraftFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //Окончание регистрации

  [actionTypes.endCompetition](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.endCompetitionStart);
      regCompAPi
        .endCompetition(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.endCompetitionSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.endCompetitionFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.endCompetitionFailure, err);
          console.log("ошибка", err);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};
