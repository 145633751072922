<template>
  <div class="draggable__new-list-item" v-bind:class="flexObject">
    <div class="draggable__color" v-bind:class="color">
      <p class="number">{{ idx + 1 }}</p>
    </div>
    <div class="draggable__info">
      <div
        class="draggable__block"
        :class="{ 'flex50-text': this.item_elem.union }"
      >
        <div class="draggable__block-startlist">
          <div class="draggable__item-startlist">
            <p class="draggable__title-index">Тип:</p>
            <div class="draggable__wrap-text">
              <p class="draggable__description">
                {{ item_elem.type }}
              </p>
            </div>
          </div>
          <div class="draggable__item-startlist">
            <p class="draggable__title-index">Разряд:</p>
            <div class="draggable__wrap-text">
              <p class="draggable__description">
                {{ item_elem.rank }}
              </p>
            </div>
          </div>
          <div class="draggable__item-startlist">
            <p class="draggable__title-index">Категория:</p>
            <div class="draggable__wrap-text">
              <p class="draggable__description">
                {{ item_elem.individualCategory }}
              </p>
            </div>
          </div>
        </div>
        <div class="draggable__block-startlist">
          <div class="draggable__item-startlist">
            <p class="draggable__title-index">Вид:</p>
            <div class="draggable__wrap-text">
              <p class="draggable__description">
                {{ item_elem.category }}
              </p>
            </div>
          </div>

          <div class="draggable__item-startlist">
            <p class="draggable__title-index">Возраст:</p>
            <div class="draggable__wrap-text">
              <p class="draggable__description">
                {{ item_elem.fromAge }} - {{ item_elem.beforeAge }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="draggable-union"
      @click="unionObject"
      v-if="isArrowShow && !this.isDisableDrag"
      tooltip="Объединить в гребенку"
      v-bind:class="{ 'union-none': item_elem.union }"
    >
      <img
        class="draggable-union__svg"
        src="../../assets/svg/data.svg"
        alt="union"
      />
    </div>
    <div
      class="draggable-not-union"
      v-if="item_elem.arrow && !this.isDisableDrag"
      @click="notUnionObject"
      tooltip="Разъединить"
      v-bind:class="{ 'union-none': !item_elem.union }"
    >
      <img
        class="draggable-not-union__svg-red"
        src="../../assets/svg/data-red.svg"
        alt="union"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppNewItamNomination",
  data() {
    return {
      validationError: "Данные категории нельзя объединить в гребенку",
    };
  },
  props: {
    item_elem: {
      type: Object,
      default() {
        return {};
      },
    },
    idx: {
      type: Number,
      default() {
        return {};
      },
    },
    indexList: {
      type: Array,
      default() {
        return {};
      },
    },
    isDisableDrag: {
      type: Boolean,
      default() {
        return {};
      },
    },
  },
  computed: {
    flexObject: function () {
      return {
        flex50: this.item_elem.union,

        flex100: !this.item_elem.union,
        // "individ-border": this.item_elem.type === "Индивидуальная",
        // "group-border": this.item_elem.type === "Группы",
      };
    },
    color: function () {
      return {
        individ: this.item_elem.type === "Индивидуальная",
        group: this.item_elem.type === "Группы",
      };
    },
    isArrowShow: function () {
      return (
        this.indexList.length > 1 && this.idx + 1 !== this.indexList.length
      );
    },
  },

  methods: {
    unionObject() {
      //Нахождение элемента в массиве
      let unionElement = this.indexList.find((element) => {
        return element === this.item_elem;
      });
      //Индекс элемента в массиве
      let indexUnionElement = this.indexList.indexOf(unionElement);
      // Идентификатов объединения
      if (
        this.indexList[indexUnionElement].idCategory ===
        this.indexList[indexUnionElement + 1].idCategory
      ) {
        //объединить
        this.indexList[indexUnionElement].union = true;
        this.indexList[indexUnionElement + 1].union = true;
        //обозначение стрелок
        this.indexList[indexUnionElement].arrow = true;
        this.indexList[indexUnionElement + 1].arrow = false;
        this.$emit("error", null);
      } else {
        console.log("Нельзя объединить в гребенку");
        this.$emit("error", this.validationError);
      }
    },
    notUnionObject() {
      let unionElement = this.indexList.find((element) => {
        return element === this.item_elem;
      });
      //Индекс элемента в массиве
      let indexUnionElement = this.indexList.indexOf(unionElement);
      // Идентификатов объединения
      if (
        this.indexList[indexUnionElement].idCategory ===
        this.indexList[indexUnionElement + 1].idCategory
      ) {
        //снять объединение
        this.indexList[indexUnionElement].union = false;
        this.indexList[indexUnionElement + 1].union = false;
        //обозначение стрелок
        this.indexList[indexUnionElement].arrow = true;
        this.indexList[indexUnionElement + 1].arrow = false;
        this.$emit("error", null);
      } else {
        console.log("Р");
      }
    },
  },
};
</script>

<style></style>
