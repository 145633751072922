<template>
  <div class="select-component">
      <input
          type="text"
          class="input-light-text"
          v-model="inputValue"
          @input="filterOptions"
          placeholder="Введите для поиска..."
      />

    <ul v-if="isOpen && filteredOptions.length" class="options-list">
      <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          class="option-item"
          @click="selectOption(option)"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
  import { actionTypes } from '@/store/modules/registration'

  export default {
    name: "AppRegionSelect",
    data() {
      return {
        inputValue: this.default
            ? this.default
            : '', // Текущее значение в поле ввода
        isOpen: false, // Статус открытия списка опций
        filteredOptions: [], // Отфильтрованные опции на основе ввода
      };
    },
    props: {
      default: {
        type: [String, Object],
        required: false,
        default: null,
      },
    },
    methods: {
      filterOptions() {
        // Проверяем, не пустой ли ввод, и открываем список опций
        if (this.inputValue.trim() !== '') {
          this.isOpen = true;
          this.disableModalScroll();
          // Отправка запроса на сервер при каждом изменении inputValue
          this.$store
              .dispatch(actionTypes.getAllRegion, { query: this.inputValue })
              .then((response) => {
                this.filteredOptions = response.result;
              })
              .catch((er) => {
                console.log("Ошибка при получении данных", er);
              });
        } else {
          // Закрытие списка опций, если ввод пустой
          this.isOpen = false;
          this.enableModalScroll();
        }
      },
      disableModalScroll() {
        // const modals = document.querySelectorAll('.entered-data');
        // modals.forEach(modal => {
        //   modal.style.setProperty('overflow-y', 'hidden', 'important');
        // });
      },
      enableModalScroll() {
        // const modals = document.querySelectorAll('.entered-data');
        // modals.forEach(modal => {
        //   modal.style.removeProperty('overflow-y');
        // });

      },
      selectOption(option) {
        // Выбор опции и закрытие списка опций
        this.inputValue = option.name;
        this.isOpen = false;
        this.enableModalScroll();
        this.$emit('regionSelected', option);
      },
    },
    beforeDestroy() {
      this.enableModalScroll(); // Убедитесь, что скролл включен при уничтожении компонента
    },
  };
</script>

<style>
.select-component {
  position: relative; /* Это позволяет использовать z-index для вложенных элементов */
  width: 100%;
  max-width: 400px; /* Или любая другая максимальная ширина по вашему выбору */
}

.input-field {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px; /* Добавляем скругление краев */
}

.options-list {
  position: absolute;
  width: 100%;
  bottom: 100%; /* Позиционируем список выше поля ввода */
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px 4px 4px 4px; /* Скругляем верхние углы */
  z-index: 1010;
}
.option-item {
  padding: 8px;
  cursor: pointer; /* Делаем курсор в виде указателя, чтобы подчеркнуть кликабельность */
  transition: background-color 0.2s; /* Добавляем плавное изменение фона при наведении */
}

.option-item:hover {
  background-color: #f0f0f0; /* Цвет фона при наведении */
}
</style>

