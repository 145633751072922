<template>
  <div class="">
    <div class="draggable-block">
      <div class="draggable-block__tag-info">
        <div class="draggable__index-1">
          <div class="create-start-list__title">
            <p class="title-mini">Индивидуальные категории</p>
          </div>
          <draggable
            :list="newList"
            class="draggable__list-group"
            @change="log"
            @start="dragging = true"
            @end="dragging = false"
            group="nomination"
          >
            <!-- :move="checkMove" -->
            <div
              class="draggable__list-group-item"
              v-for="(item, index) in newList"
              :key="index"
            >
              <div class="draggable__color"></div>
              <div class="draggable__info">
                <div class="draggable__item">
                  <p class="draggable__title-mini">Вид:</p>
                  <div class="draggable__wrap-text">
                    <p class="draggable__description-mini">
                      {{ item.category }}
                    </p>
                  </div>
                </div>
                <div class="draggable__item">
                  <p class="draggable__title-mini">Разряд:</p>
                  <div class="draggable__wrap-text">
                    <p class="draggable__description-mini">
                      {{ item.rank }}
                    </p>
                  </div>
                </div>
                <div class="draggable__item">
                  <p class="draggable__title-mini">Категория:</p>
                  <div class="draggable__wrap-text">
                    <p class="draggable__description-mini">
                      {{ item.individualCategory }}
                    </p>
                  </div>
                </div>
                <div class="draggable__item">
                  <p class="draggable__title-mini">Возраст:</p>
                  <div class="draggable__wrap-text">
                    <p class="draggable__description-mini">
                      {{ item.fromAge }} - {{ item.beforeAge }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </div>
        <div class="draggable__index-1">
          <div class="create-start-list__title">
            <p class="title-mini">Групповые категории</p>
          </div>
          <draggable
            :list="newListGroup"
            class="draggable__list-group"
            @change="log"
            @start="dragging = true"
            @end="dragging = false"
            group="nomination"
          >
            <div
              class="draggable__list-group-item"
              v-for="(item, index) in newListGroup"
              :key="index"
            >
              <div class="draggable__color2"></div>
              <div class="draggable__info2">
                <div class="draggable__item">
                  <p class="draggable__title-mini">Вид:</p>
                  <div class="draggable__wrap-text">
                    <p class="draggable__description-mini">
                      {{ item.category }}
                    </p>
                  </div>
                </div>
                <div class="draggable__item">
                  <p class="draggable__title-mini">Разряд:</p>
                  <div class="draggable__wrap-text">
                    <p class="draggable__description-mini">
                      {{ item.rank }}
                    </p>
                  </div>
                </div>
                <div class="draggable__item">
                  <p class="draggable__title-mini">Категория:</p>
                  <div class="draggable__wrap-text">
                    <p class="draggable__description-mini">
                      {{ item.individualCategory }}
                    </p>
                  </div>
                </div>

                <div class="draggable__item">
                  <p class="draggable__title-mini">Возраст:</p>
                  <div class="draggable__wrap-text">
                    <p class="draggable__description-mini">
                      {{ item.fromAge }} - {{ item.beforeAge }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
      <div class="draggable__index-2 draggable-block__moving">
        <!-- <p class="title-mini">Стартовый протокол</p> -->
        <!-- <div class="draggable__title">
          <p class="draggable-title" v-if="this.nameStartList !== ''">
            {{ this.nameStartList }}
          </p>
          <p class="draggable-title" v-else>Название стартового протокола</p>
        </div> -->

        <div>
          <!-- class="draggable__new-list" -->
          <!-- <div
            class="draggable__new-list-item-empty"
            v-if="this.indexList.length === 0"
          >
            <img
              class="draggable__svg"
              src="../../assets/svg/drag.svg"
              alt="drag"
            />
            <p class="prompt">Переносите категории в нужном порядке</p>
          </div> -->
          <div class="create-start-list__title">
            <p class="title-mini">Составление стартового протокола</p>
          </div>
          <validation-errors
            v-if="validationErrors"
            :validation-errors="validationErrors"
          />
          <draggable
            :class="{ disable: this.isDisableDrag }"
            :list="indexList"
            :disabled="isDisableDrag"
            group="nomination"
            class="draggable__new-list"
            draggable=".flex100"
            :added="addIndex"
            @change="log2"
          >
            <new-item-nomination
              v-for="(element, index) in indexList"
              :key="element.name"
              :item_elem="element"
              :idx="index"
              :indexList="indexList"
              :isDisableDrag="isDisableDrag"
              @error="error"
            />
          </draggable>
        </div>
      </div>
    </div>
    <!-- <rawDisplayer class="draggable__index-1" :value="newList" title="List" />
    <rawDisplayer
      class="draggable__index-2"
      :value="indexList"
      title="List 2"
    /> -->
    <div class="draggable__btn">
      <button
        :disabled="this.indexList.length === 0"
        v-if="!isDisableDrag"
        @click="submitStartList"
        class="button-green"
      >
        Зафиксировать стартовый
      </button>
      <button v-else @click="editStartList" class="button-green">
        Внести изменения
      </button>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
import ValidationErrors from "../ValidationErrors.vue";
import NewItemNomination from "./NewItemNomination.vue";
export default {
  name: "AppNewLisrNomination",
  display: "Simple",
  order: 0,
  data() {
    return {
      enabled: true,
      dragging: false,
      indexList: [],
      validationErrors: null,
      isDisableDrag: false,
    };
  },
  props: ["nameStartList"],
  components: {
    draggable,
    NewItemNomination,
    ValidationErrors,
  },
  computed: {
    ...mapState({
      listNomination: (state) => state.createStartList.listNomination,
      newList: (state) => state.createStartList.newList,
      listGroupNomination: (state) => state.createStartList.listGroupNomination,
      newListGroup: (state) => state.createStartList.newListGroup,
    }),
  },
  methods: {
    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    log: function (evt) {
      window.console.log(evt);
    },
    log2: function (evt) {
      window.console.log(evt);
    },
    error(error) {
      this.validationErrors = error;
    },
    submitStartList() {
      //Назначаем позицию в массиве
      this.indexList.map((el, index) => {
        el.order = index + 1;
      });
      this.isDisableDrag = true;
      this.$emit("submitStartList", this.indexList);
    },
    editStartList() {
      this.isDisableDrag = !this.isDisableDrag;
    },

    addIndex(evt) {
      console.log("add", evt);
    },
  },
};
</script>

<style></style>
