import axios from "@/api/axios";

//получения всех зарегистрированный тренеров
const getCoaches = () => {
  return axios.get(`/get/coaches`);
};
//регистрация нового тренера
const registrCoach = (info) => {
  return axios.post(`/registration/coaches`, info);
};
//удаление нового тренера
const deleteCoach = (coachesId) => {
  return axios.post(`/delete/coaches/${coachesId}`);
};
//редактирование тренера
const editCoach = (editCoach) => {
  return axios.post(`/edit/coaches`, editCoach);
};
//регистрация спортсменнов
const registrSportsmen = (data) => {
  return axios.post(`/registration/sportsman`, data);
};
//получение зарегистрированных спортменов
const getSportsmens = () => {
  return axios.get(`/get/all-user/sportsmen`);
};
//удаление спортсмена по ID
const deleteSportsmen = (sportsmenId) => {
  return axios.post(`/delete/sportsmen/${sportsmenId}`);
};
//редактирование спортсмена
const editSportsmen = (editSportsmen) => {
  return axios.post(`/edit/sportsman`, editSportsmen);
};
// регистрация групп
const registrGroup = (data) => {
  return axios.post(`/add/group`, data);
};
// получение всех групп
const getGroups = () => {
  return axios.get(`/all/group`);
};
// удаление группы по ID
const deleteGroup = (groupId) => {
  return axios.post(`/delete/group/${groupId}`);
};
//редактирование группы
const editGroup = (editGroup) => {
  return axios.post(`/edit/group`, editGroup);
};
//регистрация судьи
const registrJuj = (data) => {
  return axios.post(`/registration/judges`, data);
};
//получение всех судей
const getJuj = () => {
  return axios.get(`get_all/judges`);
};
//удаление судьи
const deleteJuj = (judgeId) => {
  return axios.post(`delete/judges/${judgeId}`);
};
//редактирование судьи
const editJuj = (editGroup) => {
  return axios.post(`/edit/judges`, editGroup);
};
export default {
  getCoaches,
  registrSportsmen,
  registrCoach,
  editCoach,
  getSportsmens,
  deleteSportsmen,
  editSportsmen,
  deleteCoach,
  registrJuj,
  editJuj,
  deleteJuj,
  getJuj,
  registrGroup,
  getGroups,
  deleteGroup,
  editGroup,
};
