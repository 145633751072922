<template>
  <div class="main-content">
    <Header message="Мандатная (Админ)" />
    <BreadCrumps/>
    <div class="mandated__container">
      <div class="detail-competition__card">
        <div class="detail-competition__card-poster">
          <img
            v-if="allInfoMandate.urlImage !== null"
            class="preview-block__img"
            :src="`${allInfoMandate.urlImage}`"
            alt="foto"
          />
          <img v-else src="../../assets/img/poster.png" alt="poster" />
        </div>
        <div class="detail-competition__card-info">
          <div class="detail-competition__cart-info-title">
            <p class="index-text">
              {{ allInfoMandate.title }}
            </p>
          </div>
          <div
            class="detail-competition__cart-info-title"
            v-if="allInfoMandate.isDraw"
          >
            <p class="text-succses">ЖЕРЕБЬЕВКА ПРОВЕДЕНА!</p>
          </div>
          <div class="detail-competition__cart-info-text">
            <p class="text-label">Место проведения:</p>
            <p class="text-description">
              {{ allInfoMandate.place }}
            </p>
          </div>
          <div class="detail-competition__cart-info-date">
            <div class="detail-competition__cart-info-date-from">
              <p class="text-label">Дата проведения:</p>
              <p class="text-description">
                {{ dateOfFromComp }}-{{ dateOfToComp }}
              </p>
            </div>
            <div class="detail-competition__cart-info-date-to">
              <p class="text-label">Приём заявок до:</p>

              <p class="text-description">{{ dateOfPrismApp }}</p>
            </div>
          </div>
          <div class="detail-competition__btn" v-if="!allInfoMandate.isDraw">
            <div class="">
              <p>Перед созданием стартового необходимо провести жеребьевку</p>
            </div>
            <button class="button-green" @click="sortitionStartList()">
              Провести жеребьевку
            </button>
          </div>
          <div class="detail-competition__btn">
            <button
              :disabled="!allInfoMandate.isDraw"
              class="button-green"
              @click="createStartList()"
            >
              Создать стартовый
            </button>
          </div>
        </div>
      </div>
      <div class="mandated__blocks">
        <div class="mandated__wrap-blocks">
          <div
            class="mandated__item-block-active"
            @click="activeBlock('sportsmens')"
            :class="{ 'mandated__item-block': !this.isActiveSportsmens }"
          >
            <p
              class="mandated__item-block-text-active"
              :class="{ 'mandated__item-block-text': !this.isActiveSportsmens }"
            >
              Инд.выступлений
            </p>
          </div>
          <div class="mandated__add-numer">
            <p class="mandated__add-number-text">
              {{ this.allInfoMandate.sportsmansCount }}
            </p>
          </div>
        </div>
        <div class="mandated__wrap-blocks">
          <div
            class="mandated__item-block-active"
            @click="activeBlock('clubs')"
            :class="{ 'mandated__item-block': !this.isActiveGroups }"
          >
            <p
              class="mandated__item-block-text-active"
              :class="{ 'mandated__item-block-text': !this.isActiveGroups }"
            >
              Групп
            </p>
          </div>
          <div class="mandated__add-numer">
            <p class="mandated__add-number-text">
              {{ this.allInfoMandate.groupsCount }}
            </p>
          </div>
        </div>
        <!-- <div class="mandated__wrap-blocks">
          <div
            class="mandated__item-block-active"
            @click="activeBlock('coachs')"
            :class="{ 'mandated__item-block': !this.isActiveCoachs }"
          >
            <p
              class="mandated__item-block-text-active"
              :class="{ 'mandated__item-block-text': !this.isActiveCoachs }"
            >
              Тренеров
            </p>
          </div>
          <div class="mandated__add-numer">
            <p class="mandated__add-number-text">
              {{ this.allInfoMandate.coachesCount }}
            </p>
          </div>
        </div> -->
        <div class="mandated__wrap-blocks">
          <div
            class="mandated__item-block-active"
            @click="activeBlock('judjes')"
            :class="{ 'mandated__item-block': !this.isActiveJudjes }"
          >
            <p
              class="mandated__item-block-text-active"
              :class="{ 'mandated__item-block-text': !this.isActiveJudjes }"
            >
              Судей
            </p>
          </div>
          <div class="mandated__add-numer">
            <p
              class="mandated__add-number-text"
              v-if="
                this.allInfoMandate.judges && this.allInfoMandate.judges.length
              "
            >
              {{ this.allInfoMandate.judges.length }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="mandated-info-juj"
        v-if="isActiveJudjes && this.allInfoMandate"
      >
        <div class="mandated-info-juj__download">
          <div
            class="mandated-info-juj__download-block"
            @click="downloadFileForJuj"
          >
            <div class="mandated-info-juj__download-text">
              <p class="mandated-info-juj__text">Скачать список</p>
            </div>

            <img src="../../assets/svg/download-file.svg" alt="" />
          </div>
        </div>
        <table-app-judge :allSportsmen="this.allInfoMandate" />

        <!-- <empty-block v-else /> -->
      </div>
      <info-about-sportsmens
        v-if="isActiveSportsmens && this.individualCategories"
        :listNominationInd="this.individualCategories"
      />
      <info-about-groups
        v-if="isActiveGroups && this.groupCategories"
        :listNominationGroup="this.groupCategories"
      />
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import { mapState } from "vuex";

import { actionTypesMandated } from "@/store/modules/mandated";
import { actionTypes } from "@/store/modules/registration";
// import EmptyBlock from "../../components/EmptyBlock.vue";
import TableAppJudge from "../../components/TableInModal/TableAppJudge.vue";
// import ItemTableJuj from "../../components/Table/ItemTableJuj.vue";
import axios from "axios";
import InfoAboutSportsmens from "./InfoAboutSportsmens.vue";
import InfoAboutGroups from "./InfoAboutGroups.vue";
import BreadCrumps from '@/components/BreadCrumps.vue'

export default {
  components: {
    BreadCrumps,
    Header,
    TableAppJudge,
    InfoAboutSportsmens,
    InfoAboutGroups,
  },
  name: "AppItemMandatedInfo",
  data() {
    return {
      isActiveBlock: false,
      isActiveGroups: false,
      isActiveCoachs: false,
      isActiveJudjes: false,
      isActiveSportsmens: true,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.competition.isLoading,
      allInfoMandate: (state) => state.mandated.allInfoMandate,
      groupCategories: (state) => state.registration.groupCategories,
      individualCategories: (state) => state.registration.individualCategories,
    }),
    dateOfFromComp() {
      return this.$moment(this.allInfoMandate.fromDate)
        .locale("ru")
        .format("LL");
    },
    dateOfToComp() {
      return this.$moment(this.allInfoMandate.toDate).locale("ru").format("LL");
    },
    dateOfPrismApp() {
      return this.$moment(this.allInfoMandate.applicationsBefore)
        .locale("ru")
        .format("LL");
    },
  },

  methods: {
    activeBlock(tab) {
      if (tab === "clubs") {
        this.isActiveCoachs = false;
        this.isActiveJudjes = false;
        this.isActiveSportsmens = false;
        this.isActiveGroups = true;
      } else if (tab === "coachs") {
        this.isActiveCoachs = true;
        this.isActiveGroups = false;
        this.isActiveJudjes = false;
        this.isActiveSportsmens = false;
      } else if (tab === "judjes") {
        this.isActiveJudjes = true;
        this.isActiveGroups = false;
        this.isActiveCoachs = false;
        this.isActiveSportsmens = false;
      } else if (tab === "sportsmens") {
        this.isActiveSportsmens = true;
        this.isActiveGroups = false;
        this.isActiveCoachs = false;
        this.isActiveJudjes = false;
      }
      // this.isActiveBlock = !this.isActiveBlock;
    },
    sortitionStartList() {
      this.$store
        .dispatch(actionTypesMandated.sortition, this.$route.params.id)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    downloadFileForJuj() {
      axios
        .get(`/get/mandate/judge/${this.$route.params.id}`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          var fileName = `СписокСудей.xls`;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
    },

    createStartList() {
      this.$router.push({
        name: "CreateStartList",
        params: {
          id: this.$route.params.id,
          // title: this.itemCompetition.title,
        },
      });
    },
  },
  mounted() {
    this.$store
      .dispatch(actionTypesMandated.getAllInfoMandated, this.$route.params.id)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
    // Получение информации о номинациях
    this.$store
      .dispatch(actionTypes.getIndividualCategories, this.$route.params.id)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
    //
    this.$store
      .dispatch(actionTypes.getGroupCategories, this.$route.params.id)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
